import React, {useEffect, useState} from 'react';
import {classNames} from "../../../app/lib";
import QuerySelect from "./query/QuerySelect";
import QueryCreate from "./query/QueryCreate";


const tabs = [
  {name: 'select', type: 'select', current: true},
  {name: 'create', type: 'create', current: false},
]

const Query = ({data, onSubmit}) => {
  const [modalOpen, setModalOpen] = useState(!!data);
  const [selected, setSelected] = useState({});
  const [tabName, setTabName] = useState(tabs[0].type);

  useEffect(() => {
    const newSelected = data?.fields?.reduce((acc, field) => {
      acc[field.name] = field.value;
      return acc;
    });
    setSelected(newSelected);
  }, []);

  useEffect(() => {
    if (data) setModalOpen(true);
    if (!data.select_options) setTabName('create')
  }, [selected]);

  const handleSelectChange = (e) => {
    const {name, value} = e.target;
    const newSelected = {...selected};
    newSelected[name] = value;
    setSelected(newSelected);
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    const newSelected = {...selected};
    newSelected[name] = value;
    setSelected(newSelected);
  };

  const handleSubmit = () => {

    const newSelected = data.fields.map(field => {
      const newField = {[field.name]: !field.disabled ? selected[field.name] : field.value}
      if (!newField[field.name]) newField[field.name] = selected[field.options_name];
      return newField;
    });
    const dataToSend = {};
    newSelected.forEach(field => {
      const key = Object.keys(field)[0];
      dataToSend[key] = field[key];
    });

    onSubmit(dataToSend);
    setModalOpen(false);
  }

  return (
    modalOpen && (
      <div className="fixed z-10 inset-0">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
          <div
            className="inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">

            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {data?.description}
                  </h3>
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="tabs"
                      name="tabs"
                      onChange={(event) => setTabName(event.target.value)}
                      className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      defaultValue={tabs.find((tab) => tab.current).name}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden sm:block mb-6">
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <button
                            key={tab.name}
                            onClick={() => setTabName(tab.type)}
                            className={classNames(
                              tab.type === tabName
                                ? 'border-blue-500 text-blue-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                          >
                            {tab.name}
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>
                  {tabName === 'create' && data?.fields && <div className="mt-2">
                    <QueryCreate fields={data?.fields} handleChange={handleChange} selected={selected}/>
                  </div>}
                  {tabName === 'select' && data?.select_options &&
                    <QuerySelect options={data?.select_options} handleSelectChange={handleSelectChange} />}
                  {!data?.select_options && tabName === 'select' && <div className="mt-2">Select options unavailable</div>}
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <span className="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Submit
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Query;
