import React from 'react';
import Table from "../../../../components/elements/Table";

const CompaniesRatesTable = ({companies, companyRates, currentCompany, setCurrentCompany, units}) => {

  const companyRatesColumns = ['ID', 'Name', 'Company', 'Rate', 'Unit'];
  const currentCompanyRates = (companyRates.filter(cr => cr.company_id === currentCompany)).map(cr => {
    return {
      'ID': cr.id,
      'Name': cr.name.replace(/_/g, ' ').toUpperCase(),
      'Company': companies.find(c => c.id === cr.company_id)?.name,
      'Rate': cr.rate,
      'Unit': units.find(u => u.id === cr.unit_id)?.name
    };
  });

  return (
    <div className="flex flex-col flex-grow">
      <h1 className="text-2xl font-semibold text-gray-900">Company Rates</h1>
      {companies.map(company => (<button className={company.id === currentCompany ? 'bg-gray-200' : `text-blue-500 hover:text-blue-600`}
        key={company.id} onClick={() => setCurrentCompany(company.id)}
                                         type="button">{company.name}</button>))}
      <Table data={currentCompanyRates} columns={companyRatesColumns}/>
    </div>
  );
};

export default CompaniesRatesTable;