import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import UploadInput from "../../../components/elements/UploadInput";
import {setEmployees} from "../../../actions";
import PageHeader from "../../../components/elements/PageHeader";
import ParagraphMissingData from "../../../components/elements/ParagraphMissingData";
import ParagraphAmount from "../../../components/elements/ParagraphAmount";
import Table from "../../../components/elements/Table";
import {useQuery} from '@tanstack/react-query';
import {fetchEmployees} from "../../hooks/queries";
import Modal from "../../../components/elements/Modal";
import api from "../../../app/request";

const EmployeesPage = React.memo(() => {
  const dispatch = useDispatch();
  const urls = useSelector(store => store.config.urls);
  const account = useSelector(store => store.account);
  const employees = useSelector(store => store.employees);
  const employeeHeader = ['ID', 'EMPLOYEE ID', 'EMPLOYEE NAME', 'EMPLOYEE EMAIL', 'EMPLOYEE PHONE'];
  const [data, setData] = useState([]);
  const [employee, setEmployee] = useState(null);

  const employeesData = useQuery({
    queryKey: ['employees'],
    queryFn: fetchEmployees,
    enabled: !employees || employees.length === 0
  });

  useEffect(() => {
    if (employeesData?.data) dispatch(setEmployees(employeesData.data));
  }, [employeesData, dispatch]);

  useEffect(() => {
    if (employees && employees.length > 0) {
      const data = employees.map(employee => {
        const phone = employee.phone_number || '';
        const formattedPhone = `+1 (${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
        return {
          'ID': employee.id,
          'EMPLOYEE ID': employee.employee_id || '',
          'EMPLOYEE NAME': `${employee.first_name} ${employee.last_name}`,
          'EMPLOYEE EMAIL': employee.email,
          'EMPLOYEE PHONE': formattedPhone
        }
      });
      setData(data);
    }
  }, [employees]);

  const handleDelete = (id) => {
    console.log('delete', id);
  }

  const handleChange = (e) => {
    setEmployee({...employee, [e.target.name]: e.target.value});
  }

  const handleEdit = (emp) => {
    const newEmployee = employees && employees.find(employee => employee.id === emp.ID);
    setEmployee(newEmployee);
  }

  const handleSave = async (e) => {
    e.preventDefault();
    const employeeToSave = {...employee}
    employeeToSave.phone_number = String(employeeToSave.phone_number.replace("+1", '').replace(/\D/g, ''));
    employeeToSave.account_id = account.id;
    api.put(`${urls.employees}/${employee.id}`, employeeToSave).then(res => {
      const newEmployees = employees.map(emp => {
        if (emp.id === res.data.id) {
          return employeeToSave;
        }
        return emp;
      });
      dispatch(setEmployees(newEmployees));
      setEmployee(() => null);
    }).catch(err => console.log(err));
  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <PageHeader header={"Employees"}/>
      {(!employees || employees.length === 0) && <ParagraphMissingData name={"employees"}/>}
      {employees && employees.length > 0 && <ParagraphAmount name={"employees"} amount={employees.length}/>}

      <UploadInput setData={setEmployees} url={`${urls.employees}${urls.upload}`}/>
      {employees && employees.length > 0 &&
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <Table columns={employeeHeader} data={data} handleDelete={handleDelete} handleEdit={handleEdit}/>
        </div>}
      <Modal open={Boolean(employee)} setOpen={setEmployee}>
        <div className="mt-3 text-center sm:mt-5">
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            Edit Employee
          </h2>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Edit employee information
            </p>
            <form onSubmit={handleSave}>
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">First
                        name</label>
                      <input type="text" name="first_name" id="first_name" autoComplete="given-name"
                             value={employee?.first_name} onChange={handleChange}
                             className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">Last
                        name</label>
                      <input type="text" name="last_name" id="last_name" autoComplete="family-name"
                             value={employee?.last_name} onChange={handleChange}
                             className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <label htmlFor="fedex_id" className="block text-sm font-medium text-gray-700">Fedex ID</label>
                      <input type="text" name="fedex_id" id="fedex_id" autoComplete="fedex-id"
                             className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                             value={employee?.fedex_id} onChange={handleChange}/>
                    </div>

                    <div className="col-span-6 sm:col-span-6">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Email
                        address</label>
                      <input type="text" name="email_address" id="email_address" autoComplete="email"
                             value={employee?.email} onChange={handleChange}
                             className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="phone_number" className="block text-sm font-medium text-gray-700">Phone
                        Number</label>
                      <input type="text" name="phone_number" id="phone_number" autoComplete="phone"
                             value={employee?.phone_number} onChange={handleChange}
                             className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="employee_id" className="block text-sm font-medium text-gray-700">Employee
                        ID</label>
                      <input type="text" name="employee_id" id="employee_id" autoComplete="employee-id"
                             value={employee?.employee_id} onChange={handleChange}
                             className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-center items-center space-x-4">
                  <button type="button"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                          onClick={() => setEmployee(null)}>
                    Cancel
                  </button>
                  <button type="submit"
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
});

EmployeesPage.displayName = 'EmployeesPage';

export default EmployeesPage;