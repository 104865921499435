import React from 'react';
import {BsDatabaseFillLock, BsFillCursorFill, BsFillDiagram3Fill, BsFillKeyFill} from "react-icons/bs";


const LearnMoreFutures = () => {
  const features = [
    {
      name: 'One-Click Payroll Processing',
      description:
        'Our app makes payroll processing as easy as clicking a button. No more manual calculations or data entry. Just select your payroll data, click "Process", and let our app do the rest.',
      icon: BsFillCursorFill,
    },
    {
      name: 'Secure Data Encryption',
      description:
        'We understand the importance of data security. That\'s why our app uses advanced encryption techniques to protect your payroll data. You can rest assured that your sensitive information is safe with us.',
      icon: BsDatabaseFillLock,
    },
    {
      name: 'Seamless Data Integration',
      description:
        'Our app integrates seamlessly with your existing time-tracking software. This means you can easily import hours worked and other relevant data directly into our app, saving you time and reducing the risk of errors.',
      icon: BsFillDiagram3Fill,
    },
    {
      name: 'Advanced Access Control',
      description:
        'We\'re enhancing our system with a secure sign-in feature. This allows employees to access their payroll data with ease, while ensuring that only authorized users can access sensitive information.',
      icon: BsFillKeyFill,
    },
  ];
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl pb-12">
            Streamlining Your Payroll Management Experience
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Our payroll app provides everything you need to deploy and manage your payroll processes effectively and
            efficiently. It's designed to simplify your payroll tasks and give you more control over your payroll
            data.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div
                    className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-500">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true"/>
                  </div>
                  <div className="text-gray-700">{feature.name}</div>
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default LearnMoreFutures;