import { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Transition } from '@headlessui/react';
import { HiOutlineCheck, HiX } from "react-icons/hi";
import {setMessage} from "../../actions";

const Notification = () => {
  const dispatch = useDispatch();
  const message = useSelector(store => store.message);
  const [show, setShow] = useState(false);
  const isError = message?.error !== undefined;

  useEffect(() => {
    if (message) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
        // Clear the message in your redux store
        dispatch(setMessage(null));
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [message, dispatch]);

  const closeNotification = () => {
    setShow(false);
    dispatch(setMessage(null));
  };

  return (
    <>
      {message &&
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end justify-end px-4 py-6 sm:px-6 z-50"
        >
          <div className="w-full max-w-sm">
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform ease-in duration-100 transition"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <HiOutlineCheck className={`h-6 w-6 ${isError ? 'text-red-400' : 'text-green-400'}`} aria-hidden="true" />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">{isError ? message.error : message.message}</p>
                    </div>

                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      }
    </>
  );
}

export default Notification;
