import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {FaRegQuestionCircle} from "react-icons/fa";

const QuestionPopover = ({children}) => {
  const [show, setShow] = useState(false);
  return (<div className="inline-block">
      <FaRegQuestionCircle onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}/>
      {show && <div className="absolute bg-gray-100 text-gray-600 rounded p-4 shadow-md w-64">
        {children}
      </div>}
    </div>
  );
}


const TimeSheetsToReview = ({headers, unmatched, toggleRemove}) => {
  const companyRates = useSelector(state => state.companyRates);
  const companies = useSelector(state => state.companies);
  return (
    <div>
      <h1 className="text-2xl my-4 font-bold text-gray-600">Unmatched time</h1>
      <p>
        Please review employees time data, what wasn't matched with any deliveries. If employee didn't work that day
        please delete the time data.
      </p>
      <div>
        All unmatched time data will be paid at <span className="text-red-500 font-bold">hourly rate</span>.
        <QuestionPopover>
          <div className="inline-block">
            Hourly rate is determined by companies pay rate for that employee.
            <h2>Current hourly pay rate</h2>
            <div>
              {companies?.map((company, index) => (
                <div key={index}>
                  <span className="font-bold">{company.name}: </span> {companyRates.find(rate => rate.company_id === company.id && rate.code === 'trainee_rate')?.rate}
                </div>
              ))}
            </div>
          </div>
        </QuestionPopover>
      </div>
      <table className="table-auto w-full text-center mt-12">
        <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="px-4 py-2">
              {header.split('_').join(' ').toUpperCase()}
            </th>
          ))}
          <th className="px-4 py-2">
            REMOVE
          </th>
        </tr>
        </thead>
        <tbody>
        {unmatched.map((time, index) => (
          <tr key={index}>
            {headers.map((header, index) => (
              <td key={index} className="border px-4 py-2">
                {index === 0 && time[header].first_name + ' ' + time[header].last_name}
                {index === 1 && time[header].name}
                {index === 2 && new Date(time[header]).toLocaleDateString()}
                {index === 3 && time[header]}
              </td>
            ))}
            <td onClick={() => toggleRemove(time)}
                className={`text-white font-bold py-2 px-4 rounded cursor-pointer${time.remove ? ' bg-green-800' : ' bg-red-700'}`}>
              {time.remove ? 'Cancel' : 'Remove'}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default TimeSheetsToReview;