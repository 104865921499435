import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {capitalize} from "../../../../app/lib";
import Select from "../../../../components/elements/Select";
import {useQuery} from "@tanstack/react-query";
import api from "../../../../app/request";
import {setPayTypes} from "../../../../actions";


const redirectUser = (cb) => {
  setTimeout(cb, 3000);
};


const PayrollRow = ({row, index, handleRemoveRow, handleQuantityChange, handleSelect, handleSelectRate}) => {
  const payTypes = useSelector(state => state.payTypes);
  const payType = payTypes?.length > 0 ? payTypes.find(p => p.id === row.pay_type_id) : null;
  const companyRates = useSelector(state => state.companyRates);


  return (
    <div className={`flex border-t py-2 ${index % 2 === 0 ? 'bg-gray-100' : ''}`} key={index}>
      <div className="flex space-x-2 w-full">
        <div className="font-bold ml-4">
          <span>
            {payType && payType?.description.split("_").map(item => capitalize(item)).join(" ")}
            {payTypes?.length > 0 && !payType && <Select options={payTypes || []} selected={payTypes[0]} setSelected={handleSelect} name="description"/>}
          </span>
        </div>
      </div>
      <input
        type="number"
        value={row.quantity}
        onChange={(e) => handleQuantityChange(e, row.id)}
        className="w-48 border rounded-lg px-2 py-1 border-gray-200 mx-6"
      />
      <span className="">x</span>
      <span className="w-48 mx-6">
        {row.rate !== -1 && `$${row.rate}`}
        {row.rate === -1 && <Select options={companyRates} selected={companyRates[0]} setSelected={handleSelectRate}/>}
      </span>
      <span className="w-60 px-1">Total: ${Math.round(row.quantity * row.rate * 100) / 100}</span>
      {/* Add Remove Button Here */}
      <button onClick={() => handleRemoveRow(row.id)}>Remove</button>
    </div>
  )
}

const PayrollPage = () => {
  const {payrollId} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payrolls = useSelector(state => state.payrolls);
  const employees = useSelector(state => state.employees);
  const companies = useSelector(state => state.companies);
  const companyRates = useSelector(state => state.companyRates);
  const payTypes = useSelector(state => state.payTypes);
  const [payroll, setPayroll] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [total, setTotal] = useState(0);

  const payTypesData = useQuery({
    queryKey: 'payTypes',
    queryFn: () => api.get('/pay-types/'),
    enabled: Boolean(!payTypes || !payTypes.length)
  })


  useEffect(() => {
    redirectUser(() => {
      if (!payrolls?.length || !employees?.length) {
        navigate('/account/payrolls')
        return;
      }
    })

    redirectUser(() => {
      const currentPayroll = payrolls.find(p => p.id === Number(payrollId));
      if (!currentPayroll) {
        navigate('/account/payrolls')
        return;
      }
    })

    if (payTypesData.data && payTypesData.data?.data?.length > 0) {
      dispatch(setPayTypes(payTypesData.data?.data));
    }
    const currentPayroll = payrolls.find(p => p.id === Number(payrollId));
    const currentTotal = currentPayroll.pay_rows.reduce((acc, row) => acc + row.total, 0);
    setTotal(currentTotal);
    setPayroll(currentPayroll);
    setEmployee(employees.find(e => e.id === currentPayroll.employee_id));
  }, [payrollId, payrolls, employees, navigate, payTypesData.data, dispatch]);

  const handleRemoveRow = (rowId) => {
    const updatedRows = payroll.pay_rows.filter(row => row.id !== rowId);

    console.log(updatedRows);
    // Update your state here
  };

  const handleAddRow = () => {
    const newRowData = {
      id: Math.floor(Math.random() * 1000),
      pay_type_id: 0,
      quantity: 0,
      rate: -1,
      total: 0,
    }
    const updatedRows = [...payroll.pay_rows, newRowData];
    const updatedPayroll = {
      ...payroll,
      pay_rows: updatedRows,
    }
    console.log(updatedRows);
    // Update your state here
    setPayroll(() => updatedPayroll);
  };

  const handleQuantityChange = (e, rowId) => {
    const updatedRows = payroll.pay_rows.map(row => {
      if (row.id === rowId) {
        return {...row, quantity: e.target.value}
      }
      return row;
    });
    const currentTotal = updatedRows.reduce((acc, row) => acc + row.total, 0);
    setTotal(currentTotal);
    const updatedPayroll = {
      ...payroll,
      pay_rows: updatedRows,
    }
    console.log(updatedRows);
    setPayroll(() => updatedPayroll);
  }

  const handleSelect = (e, rowId) => {
    console.log(e);
    const updatedRows = payroll.pay_rows.map(row => {
      if (row.id === rowId) {
        return {...row, pay_type_id: e?.id}
      }
      return row;
    });
    const currentTotal = updatedRows.reduce((acc, row) => acc + row.total, 0);
    setTotal(currentTotal);
    const updatedPayroll = {
      ...payroll,
      pay_rows: updatedRows,
    }
    console.log(updatedRows);
    setPayroll(() => updatedPayroll);
  }

  const handleSelectRate = (e, rowId) => {
    console.log(e);
  }

  return (
    <div className="p-8">
      <div className="border p-4 rounded-lg shadow-lg">
        <Link to="/account/payrolls"
              className="py-1 px-2 text-blue-500 hover:underline float-right flex justify-center items-center">
          <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
          </svg>

          Back to payrolls</Link>
        {payroll && (
          <>
            <div className="mb-2"><strong>For period:</strong> {payroll.week_start} - {payroll.week_end}</div>
            <div className="mb-2"><strong>Company:</strong> {companies.find(c => c.id === payroll.company_id)?.name}
            </div>
            <div className="mb-2"><strong>Employee:</strong> {employee?.first_name} {employee?.last_name}</div>
            <div className="mb-2"><strong>Days worked:</strong> {payroll.days_worked}</div>
            {payroll.pay_rows.map((row, i) =>
              <PayrollRow row={row} index={i} handleRemoveRow={handleRemoveRow} handleSelect={handleSelect}
                          handleQuantityChange={handleQuantityChange} handleSelectRate={handleSelectRate}/>)}
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                onClick={handleAddRow}
              >
                Add Row
              </button>
            </div>
            <div className="flex justify-end mt-4">
              <span className="font-bold">Total: ${total.toFixed(2)}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PayrollPage;
