import {Fragment, useEffect, useState} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {HiCheck, HiChevronDown} from "react-icons/hi2";
import {classNames} from "../../../../app/lib";
import {useSelector} from "react-redux";


const Select = ({
                  selected,
                  setSelected,
                  options,
                  name,
                  display_names = ['name'],
                  replace = false,
                  options_name,
                  value,
                  onChange,
                  disabled,
                  filter = null
                }) => {
  const localOptionsFromState = useSelector(state => state[options_name]);
  const [localOptions, setLocalOptions] = useState([]);
  const [displayName, setDisplayName] = useState({name: ''});

  useEffect(() => {
    const current = (value && localOptions) ? localOptions.find(option => option.id === value) : localOptions[0];

    if (!localOptions[0] || !current) return;
    const e = {target: {name: options_name, value: current || localOptions[0]}};
    if (!selected) setSelected(e);

    const newDisplayName = display_names.map((name, index) => {
      return current[name] || localOptions[0][name];
    }).join(' ');

    const newName = {name: newDisplayName};
    setDisplayName(newName);

  }, [localOptions, selected, value]);

  useEffect(() => {
    if (localOptionsFromState) {
      let newOptions;
      if (filter) {
        newOptions = localOptionsFromState.filter(option => option[filter.name] === filter.value);

      } else {
        newOptions = localOptionsFromState.map(option => {
          return replace ? {...option, name: option[replace]} : option;
        });
      }
      console.log("options_name: ", options_name);
      setLocalOptions(newOptions);
      if (value) {
        const findSelected = newOptions.find(option => option.id === value);
        console.log("findSelected: ", findSelected);
        const e = {target: {name: options_name, value: findSelected}};
        if (findSelected) onChange(e);
      } else {
        const e = {target: {name: options_name, value: newOptions[0]}};
        console.log("e: ", e);
        onChange(e);
      }
    }
  }, [localOptionsFromState, value]);

  const handleChange = (e) => {
    setSelected(e);
    onChange(e);
  }

  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>


      {({open}) => (
        <div className="relative mt-2">
          <Listbox.Button
            className={`relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6${disabled && ' disabled:text-gray-400'}`}>
            <span className="block truncate">{displayName.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <HiChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true"/>
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-50"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options style={{zIndex: 1000}}
                             className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {localOptions?.map((item, index) => (
                <Listbox.Option
                  key={index}
                  className={({active}) =>
                    classNames(
                      active ? 'bg-blue-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  value={item}
                  onChange={handleChange}
                >
                  {({selected, active}) => {
                    const getName = (name) => item[name];

                    const display_name = display_names.map(name => getName(name)).join(' ');
                    return (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {display_name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <HiCheck className="h-5 w-5" aria-hidden="true"/>
                          </span>
                        ) : null}
                      </>
                    )
                  }}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}


export default Select;