import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DateInput from "../../../../src/components/DateInput";
import TimeInput from "../../../components/TimeInput";
import {setMessage} from "../../../actions";

const DisplayVIR = ({data, setData, startDay, endDay, onError}) => {
  const dispatch = useDispatch();
  const vehicles = useSelector(store => store.vehicles);
  const employees = useSelector(store => store.employees);
  const [rows, setRows] = useState([]);
  const dateInputRef = useRef(null);
  const timeInputRef = useRef(null);
  const [isRowsValid, setIsRowsValid] = useState(true);
  const headersMapping = {
    'First Name': 'first_name',
    'Last Name': 'last_name',
    'Mobile phone': 'phone_number',
    'Email': 'email',
    'Submission Date': 'submission_date',
    'Vehicle Number': 'vehicle_number',
    'Submission Time': 'submission_time',
  }

  const validateRows = useCallback((localRows) => {
    for (let i = 0; i < localRows.length; i++) {
      const row = localRows[i];
      for (let y = 0; y < Object.keys(row).length; y++) {
        const key = Object.keys(row)[y];
        if (!row[key].valid) {
          return setIsRowsValid(false);
        }
      }
    }
    setIsRowsValid(true)
  }, []);

  const validateData = useCallback((data, vehicles) => {
    const weekStart = new Date(startDay);
    const weekEnd = new Date(endDay);
    const submissionDate = new Date(data[0]['Submission Date']);
    const _rows = [];

    if (data.length === 0 || !data[0]['Mobile phone']) {
      return dispatch(setMessage({error: 'Please upload a valid vehicle inspections file.'}));
    }

    data.forEach((row, index) => {
      const phone_number = row['Mobile phone']?.replace(/\D/g, '');
      const employee = employees.find(e => e.phone_number === phone_number || e.email === row['Email']?.trim());
      const vehicle = vehicles.find(v => v.vehicle_number === row['Vehicle Number']?.trim());
      const isDateValid = submissionDate >= weekStart && submissionDate <= weekEnd;
      if (!employee) {
        dispatch(setMessage({error: `Cannot find employee: phone: ${row['First name']} ${row['Last name']}. \n Aborting...`}));
        onError(null)
        return;
      }

      _rows.push({
        'ID': {
          employee_id: employee.id,
          value: employee.id,
          valid: Boolean(employee)
        },
        'First Name': {
          first_name: employee.first_name,
          value: employee.id,
          valid: Boolean(employee)
        },
        'Last Name': {
          last_name: employee.last_name,
          value: employee.id,
          valid: Boolean(employee)
        },
        'Mobile phone': {
          phone_number: row['Mobile phone'],
          value: employee.id,
          valid: Boolean(employee && row['Mobile phone'] && row['Mobile phone'].length > 0)
        },
        'Email': {
          email: row['Email'],
          value: employee.id,
          valid: Boolean(employee && row['Email'] && row['Email'].length > 0)
        },
        'Vehicle Number': {
          vehicle_number: vehicle?.vehicle_number || row['Vehicle Number'],
          value: vehicle?.vehicle_number || row['Vehicle Number'].trim(),
          valid: Boolean(vehicle)
        },
        'Submission Date': {
          submission_date: row['Submission Date'].trim(),
          value: row['Submission Date'],
          valid: isDateValid
        },
        'Submission Time': {
          submission_time: row['Submission Time'].trim(),
          value: row['Submission Time'],
          valid: Boolean(row['Submission Time'] && row['Submission Time'].length > 0)
        },
      });
    });
    validateRows(_rows)
    return _rows;
  }, [employees, startDay, endDay, validateRows]);


  useEffect(() => {
    const result = validateData(data, vehicles);
    if (result.payload) return;
    else setRows(result);
  }, [data, vehicles, validateData]);

  const handleInputChange = (e, index) => {
    const {name, value} = e.target;
    const parts = name.split('-');
    const field = parts[0];
    const i = Number(parts[1]);
    const newRows = [...rows];
    if (field === 'Vehicle Number') {
      const selectedVehicle = vehicles.find(v => v.vehicle_number === value);
      newRows[i][field].valid = true;
      newRows[i][field].value = selectedVehicle.vehicle_number;
      newRows[i][field].vehicle_number = selectedVehicle.vehicle_number;
    } else {
      const selectedEmployee = employees.find(e => e.id === value);
      newRows[i][field].valid = true;
      newRows[i][field].value = selectedEmployee.id;
      newRows[i][field][headersMapping[field]] = selectedEmployee[headersMapping[field]];
    }
    setRows(newRows);
    validateRows(newRows);
  }

  const sendRows = () => {
    const _rows = [...rows].map(row => {

      return {
        employee_id: row['ID'].value,
        vehicle_number: row['Vehicle Number'].value,
        submission_date: row['Submission Date'].value,
        submission_time: row['Submission Time'].value,
      };
    });
    setData(_rows);
  }

  const handleTimeChange = (newTime, rowIndex) => {
      setRows(currentRows => {
        return currentRows.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              'Submission Time': {
                ...row['Submission Time'],
                submission_time: newTime,
                valid: newTime ? true : false // Update validity based on newTime
              }
            };
          }
          return row;
        });
      });
    };


  return (
    <div className="p-8">
      <table className="table-auto min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
        <tr>
          {/* Render table headers */}
          {rows.length > 0 && Object.keys(rows[0]).map((key, index) => (
            <th key={index}>{key}</th>
          ))}
        </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
        {rows.map((row, index) => (
          <tr key={index}>
            {/* Render table cells */}
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {!row['ID'].valid && <select>
                {employees.map((employee, i) => (
                  <option key={i} value={employee.id}>{employee.first_name} {employee.last_name}</option>
                ))}
              </select>}
              {row['ID'].valid && row['ID'].employee_id}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {!row['First Name'].valid && <select>
                {employees.map((employee, i) => (
                  <option key={i} value={employee.id}>{employee.first_name} {employee.last_name}</option>
                ))}
              </select>}
              {row['First Name'].valid && row['First Name'].first_name.substring(0, 8) + '...'}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {!row['Last Name'].valid && <select>
                {employees.map((employee, i) => (
                  <option key={i} value={employee.id}>{employee.first_name} {employee.last_name}</option>
                ))}
              </select>}
              {row['Last Name'].valid && row['Last Name'].last_name.substring(0, 8) + '...'}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {!row['Mobile phone'].valid && <select>
                {employees.map((employee, i) => (
                  <option key={i} value={employee.id}>{employee.first_name} {employee.last_name}</option>
                ))}
              </select>}
              {row['Mobile phone'].valid && row['Mobile phone'].phone_number}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {!row['Email'].valid && <select>
                {employees.map((employee, i) => (
                  <option key={i} value={employee.id}>{employee.first_name} {employee.last_name}</option>
                ))}
              </select>}
              {row['Email'].valid && row['Email'].email.substring(0, 8) + '...'}
            </td>
            <td className="whitespace-nowrap px-3 py-4">
              <select
                name={`Vehicle Number-${index}`}
                onChange={(e) => handleInputChange(e, index)}
                className={`border w-full text-sm py-1 focus:border-green-600 ${row['Vehicle Number'].valid ? 'border-green-600' : 'border-red-600'}`}
                value={row['Vehicle Number'].valid ? row['Vehicle Number'].value : ''}
              >
                {!row['Vehicle Number'].valid && <option value="" disabled>Select vehicle</option>}
                {vehicles.map((vehicle, i) => (
                  <option key={i} value={vehicle.id}>{vehicle.vehicle_number}</option>
                ))}
              </select>
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {row['Submission Date'].valid && row['Submission Date'].submission_date}
              {!row['Submission Date'].valid && <DateInput inputRef={dateInputRef} index={index}/>}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <TimeInput
                value={row['Submission Time'].submission_time}
                onChange={(newTime) => handleTimeChange(newTime, index)}
                index={index}
              />
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      <button
        className={`w-full px-4 py-2 text-white border rounded hover:bg-gray-500 ${isRowsValid ? 'bg-blue-500 border-blue-500' : 'bg-gray-500 border-gray-500'}`}
        type="button" disabled={!isRowsValid} onClick={sendRows}>submit
      </button>
    </div>
  );
};

export default DisplayVIR;