import React from 'react';

const PageHeader = ({header}) => {
  return (
    <h2 className="mx-auto text-2xl max-w-2xl font-semibold leading-6 text-gray-800 lg:mx-0 lg:max-w-none">
      {header}
    </h2>
  );
};

export default PageHeader;