import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useMutation, useQuery} from "@tanstack/react-query";
import api from "../../../app/request";
import {setShiftTypes, setPayStructures, setMessage, setCompanies} from "../../../actions";
import Input from "../../../components/elements/Input";
import Button from "../../../components/elements/Button";
import Select from "../../../components/elements/Select";
import ShiftTypesTable from "../components/ShiftTypesTable";
import {fetchCompanies, fetchPayStructures, fetchShiftTypes} from "../../hooks/queries";


const ShiftTypesPage = () => {
  const dispatch = useDispatch();
  const urls = useSelector(state => state.config.urls);
  const shiftTypes = useSelector(state => state.shiftTypes);
  const payStructures = useSelector(state => state.payStructures);
  const companies = useSelector(state => state.companies);



  // State variables
  const [shiftType, setShiftType] = useState('');
  const [selectedPayStructure, setSelectedPayStructure] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  // Requests
  const payStructuresData = useQuery({
    queryKey: ['payStructures'],
    queryFn: fetchPayStructures,
    enabled: !payStructures || payStructures.length === 0
  });
  const shiftTypesData = useQuery({
    queryKey: ['shiftTypes'],
    queryFn: fetchShiftTypes,
    enabled: !shiftTypes || shiftTypes.length === 0
  });
  const companiesData = useQuery({
    queryKey: ["companies"],
    queryFn: fetchCompanies,
    enabled: !companies || companies.length === 0,
  });

  const mutation = useMutation((data) => api.post(`${urls.shiftTypes}/`, data), {
    onSuccess: (res) => {
      if (res?.status === 201 && res?.data) {
        const currentShiftTypes = shiftTypes ? shiftTypes : [];
        const payload = [...currentShiftTypes, res.data];
        dispatch(setShiftTypes(payload));
      }
    }
  });

  useEffect(() => {
    if (payStructuresData?.data && payStructuresData?.data?.length > 0
    ) dispatch(setPayStructures(payStructuresData.data));
    if (shiftTypesData?.data && shiftTypesData?.data?.length > 0
    ) dispatch(setShiftTypes(shiftTypesData.data));
    if (companiesData?.data && companiesData?.data?.length > 0
    ) dispatch(setCompanies(companiesData.data));
  }, [payStructuresData, shiftTypesData, dispatch]);


  useEffect(() => {
    if (payStructures && payStructures.length > 0) setSelectedPayStructure(payStructures[0]);
    if (companies && companies.length > 0) setSelectedCompany(companies[0]);
  }, [payStructures, companies, dispatch]);

  const handleChange = (e) => {
    setShiftType(e.target.value);
  }

  const handleSubmit = async () => {
    mutation.mutate({
      name: shiftType.toLowerCase(),
      pay_structure_id: selectedPayStructure.id,
      company_id: selectedCompany.id
    });
    setShiftType('');
  }

  return (
    <div className='px-6'>
      <div className="grid md:grid-cols-12 md:gap-6 items-center mb-4">
        <div className="md:col-span-4 relative w-full group ">
          <Input type="text" name="shiftType" value={shiftType} onChange={handleChange} label={'Shift Type'}/>
        </div>
        <div className="md:col-span-3 relative w-full group">
          {payStructures && payStructures.length > 0 && <Select style={{zIndex: 1}} name={'payStructures'}
                                                                options={payStructures}
                                                                selected={selectedPayStructure || payStructures[0]}
                                                                setSelected={setSelectedPayStructure}/>}
        </div>
        <div className="md:col-span-3 relative w-full group">
          {companies && companies.length > 0 && <Select style={{zIndex: 1}}
                                                        options={companies} name={'companies'}
                                                        selected={selectedCompany || companies[0]}
                                                        setSelected={setSelectedCompany}/>}
        </div>
        <div className="md:col-span-2 relative w-full group">
          <Button children="Submit" onClick={handleSubmit} className="mt-1" style={{zIndex: 1}}/>
        </div>
      </div>
      {shiftTypes && shiftTypes.length > 0 && <ShiftTypesTable/>}
    </div>
  );
};

export default ShiftTypesPage;