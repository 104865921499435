import * as types from './types';
import {SET_CREDENTIALS} from "./types";

export const resetState = () => ({
  type: types.RESET_STATE,
});

export const setUser = (user) => ({
  type: types.SET_USER,
  payload: user
})

export const setMessage = (data) => ({
  type: types.SET_MESSAGE,
  payload: data
})

export const setLoading = (data) => ({
  type: types.SET_LOADING,
  payload: data
});

export const setRoutes = (data) => ({
  type: types.SET_ROUTES,
  payload: data
});

export const setVacations = (data) => ({
  type: types.SET_VACATIONS,
  payload: data
});

export const setSickLeaves = (data) => ({
  type: types.SET_SICK_LEAVES,
  payload: data
});

export const setCredentials = (data) => ({
  type: types.SET_CREDENTIALS,
  payload: data
});

export const setServerStatus = (status) => ({
  type: types.SET_SERVER_STATUS,
  payload: status
});

export const setFedexId = (data) => ({
  type: types.SET_FEDEX_ID,
  payload: data
})

export const setTimeData = (data) => ({
  type: types.SET_TIME_DATA,
  payload: data
})

export const setOutputData = (data) => ({
  type: types.SET_OUTPUT_DATA,
  payload: data
})

export const setRevenueData = (data) => ({
  type: types.SET_REVENUE_DATA,
  payload: data
})

export const setUsers = (data) => ({
  type: types.SET_USERS,
  payload: data
})

export const setVehicles = (data) => ({
  type: types.SET_VEHICLES,
  payload: data
})


export const setPayrolls = (data) => ({
  type: types.SET_PAYROLLS,
  payload: data
})


export const setCompanies = (data) => ({
  type: types.SET_COMPANIES,
  payload: data
})


export const setSubCompanies = (data) => ({
  type: types.SET_SUB_COMPANIES,
  payload: data
})


export const setRevenues = (data) => ({
  type: types.SET_REVENUES,
  payload: data
})


export const setVIR = (data) => ({
  type: types.SET_VIR,
  payload: data
})


export const setPassedCompanyIds = (data) => ({
  type: types.SET_PASSED_COMPANY_IDS,
  payload: data
})


export const setChosenDate = (data) => ({
  type: types.SET_CHOSEN_DATE,
  payload: data
})


export const setPayrollSPlus = (data) => ({
  type: types.SET_PAYROLLS_PLUS,
  payload: data
})


export const setAccessToken = (data) => ({
  type: types.SET_ACCESS_TOKEN,
  payload: data
})


export const setAccount = (data) => ({
  type: types.SET_ACCOUNT,
  payload: data
})

export const setDeliveryData = (data) => ({
  type: types.SET_DELIVERY_DATA,
  payload: data
})

export const setRevenueDataset = (data) => ({
  type: types.SET_REVENUE_DATASET,
  payload: data
})

export const setEmployees = (data) => ({
  type: types.SET_EMPLOYEES,
  payload: data
})

export const setStartDay = (data) => ({
  type: types.SET_START_DAY,
  payload: data
})

export const setEndDay = (data) => ({
  type: types.SET_END_DAY,
  payload: data
})

export const setCompanyRates = (data) => ({
  type: types.SET_COMPANY_RATES,
  payload: data
})


export const setUnits = (data) => ({
  type: types.SET_UNITS,
  payload: data
})


export const setShiftTypes = (data) => ({
  type: types.SET_SHIFT_TYPES,
  payload: data
})


export const setPayStructures = (data) => ({
  type: types.SET_PAY_STRUCTURES,
  payload: data
})


export const setTemplate = (data) => ({
  type: types.SET_TEMPLATE,
  payload: data
})

export const setPayTypes = (data) => ({
  type: types.SET_PAY_TYPES,
  payload: data
})