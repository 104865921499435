import React, {useState} from 'react';
import { Switch } from '@headlessui/react';

const SecuritySwitcher = ({item, onChange}) => {
  const [enabled, setEnabled] = useState(item.isEnabled);
  const handleSwitch = () => {
    onChange(item.id, !enabled);
    setEnabled(!enabled);
  }
  return (
    <Switch.Group as="div" className="flex items-center justify-between mb-6">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
          {item.name}
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          {item.description}
        </Switch.Description>
      </span>
      <Switch
        checked={Boolean(enabled)}
        onChange={handleSwitch}
        className={`${enabled ? 'bg-blue-500' : 'bg-gray-200' }
         relative inline-flex h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none shadow`
        }
      >
        <span
          aria-hidden="true"
          className={`${enabled ? 'translate-x-6' : 'translate-x-0' }
            pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`
          }
        />
      </Switch>
    </Switch.Group>
  );
};

export default SecuritySwitcher;