import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "@tanstack/react-query";
import {
  fetchCompanies,
  fetchCompanyRates,
  fetchEmployees,
  fetchPayStructures, fetchPayTypes,
  fetchShiftTypes, fetchUnits,
  fetchVehicles
} from "../hooks/queries";
import {
  setCompanies,
  setCompanyRates,
  setEmployees, setMessage,
  setPayStructures, setPayTypes,
  setShiftTypes,
  setUnits,
  setVehicles
} from "../../actions";

const DataManager = ({children}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const account = useSelector(store => store.account);
  const companies = useSelector(store => store.companies);
  const vehicles = useSelector(store => store.vehicles);
  const employees = useSelector(store => store.employees);
  const routes = useSelector(store => store.config.routes);
  const units = useSelector(store => store.units);
  const payTypes = useSelector(store => store.payTypes);

  // requests
  const companiesData = useQuery({
    queryKey: ["companies"],
    queryFn: fetchCompanies,
    enabled: Boolean(!companies || companies.length === 0),
  });

  const vehiclesData = useQuery({
    queryKey: ["vehicles"],
    queryFn: fetchVehicles,
    enabled: Boolean(!vehicles || vehicles.length === 0),
  });
  const employeesData = useQuery({
    queryKey: ["employees"],
    queryFn: fetchEmployees,
    enabled: Boolean(!employees || employees.length === 0),
  });
  const companyRatesData = useQuery({
    queryKey: ["companyRates"],
    queryFn: fetchCompanyRates,
    enabled: Boolean(!employees || employees.length === 0),
  });
  const shiftTypesData = useQuery({
    queryKey: ["shiftTypes"],
    queryFn: fetchShiftTypes,
    enabled: Boolean(!employees || employees.length === 0),
  });
  const payStructuresData = useQuery({
    queryKey: ["payStructures"],
    queryFn: fetchPayStructures,
    enabled: Boolean(!employees || employees.length === 0),
  });
  const monthlyRevenueData = useQuery({
    queryKey: ["payStructures"],
    queryFn: fetchPayStructures,
    enabled: Boolean(!employees || employees.length === 0),
  });

  const payTypesData = useQuery({
    queryKey: ["payTypes"],
    queryFn: fetchPayTypes,
    enabled: Boolean(!payTypes || payTypes.length === 0),
  });

  const unitsData = useQuery({
    queryKey: ["units"],
    queryFn: fetchUnits,
    enabled: Boolean(!units || units.length === 0)
  });

  useEffect(() => {
    const errors = [];
    if (!account) return navigate(routes.home);
    if (payStructuresData?.data && payStructuresData?.data.length > 0) dispatch(setPayStructures(payStructuresData.data));
    if (shiftTypesData?.data && shiftTypesData?.data.length > 0) dispatch(setShiftTypes(shiftTypesData.data));
    if (companiesData?.data && companiesData?.data.length > 0) dispatch(setCompanies(companiesData.data));
    else if (vehiclesData?.data && companiesData?.data?.length === 0) errors.push('companies');
    if (vehiclesData?.data && vehiclesData?.data.length > 0) dispatch(setVehicles(vehiclesData.data));
    else if (vehiclesData?.data && vehiclesData?.data?.length === 0) errors.push('vehicles');
    if (employeesData?.data && employeesData?.data.length > 0) dispatch(setEmployees(employeesData.data));
    else if (vehiclesData?.data && employeesData?.data?.length === 0) errors.push('employees');
    if (companyRatesData?.data && companyRatesData?.data.length > 0)
      dispatch(setCompanyRates(companyRatesData.data));
    if (unitsData?.data && unitsData?.data.length > 0) dispatch(setUnits(unitsData.data));
    if (errors.length > 0) {
      const error = 'You have no ' + errors.join(', ') + ' yet. Please create one first.';
      dispatch(setMessage({error}));
    }
    if (payTypesData?.data && payTypesData?.data?.length > 0) dispatch(setPayTypes(payTypesData.data));
    if (payStructuresData?.data && payStructuresData?.data?.length > 0) dispatch(setPayStructures(payStructuresData.data));
  }, [
    companiesData.data,
    vehiclesData.data,
    employeesData.data,
    companyRatesData.data,
    shiftTypesData.data,
    unitsData.data,
    dispatch,
    account,
    navigate,
    routes.home,
    payTypesData.data,
    payStructuresData.data,
    companies, employees, navigate, routes.account, routes.companies, routes.employees, routes.vehicles, vehicles
  ]);
  return <>{children}</>;
};

export default DataManager;