import React from 'react';
import Chart from "./Chart";
import {useSelector} from "react-redux";
import LineButton from "../elements/LineButton";

const CompanyRevenue = () => {
  const companies = useSelector(store => store.companies);
  const companyRevenue = useSelector(store => store.charts.companyRevenue);
  const revenue = useSelector(store => store.revenue);
  const [currentCompany, setCurrentCompany] = React.useState(companies[0] || null);
  const currentCompanyRevenue = revenue && revenue[currentCompany?.id];
  const data = currentCompanyRevenue?.map(r => [r.month, r.amount]);
  const dataset = data ? data : companyRevenue;

  return (
    <div className="h-[24rem]">
      <h1 className="text-xl text-gray-600 pb-8">{currentCompany.name} monthly revenue</h1>
      {companies && companies.map(company => (
        <LineButton key={company.name} label={company.name} onClick={() => setCurrentCompany(company)}
                    additionalClasses={`${company.id === currentCompany.id ? ' bg-gray-100' : ' bg-white'}`}/>
      ))}
      <Chart data={dataset} type="bar"/>
    </div>

  );
};

export default CompanyRevenue;