import React from 'react';
import UnderstandPayroll from "../../../components/charts/UnderstandPayroll";
import MyIncome from "../../../components/charts/MyIncome";
import useAuthRedirect from "../../hooks/useAuthRedirect";

const Dashboard = () => {
  useAuthRedirect();
  return (
    <div className="pr-8">
      <UnderstandPayroll/>
      <MyIncome/>
    </div>
  );
};

export default Dashboard;