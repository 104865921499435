import React from 'react';
import useAuthRedirect from "../../hooks/useAuthRedirect";
import {useDispatch, useSelector} from "react-redux";
import UserInfoItem from "../elements/UserInfoItem";
import {setUser} from "../../../actions";
import api from "../../../app/request";
import UserImage from "../elements/UserImage";

const UserInfo = () => {
  const commonClasses = "mt-2 space-y-6 divide-y divide-gray-100 border border-gray-50 text-sm leading-6 p-8 rounded-md shadow";
  const dispatch = useDispatch();
  const user = useSelector(store => store.user);
  const config = useSelector(store => store.config);

  const handleChange = (field, value) => {
    const updatedUser = {...user, [field]: value};
    dispatch(setUser(updatedUser));
  };

  const handleSave = () => {
    api.put(`${config.urls.users}/${user.id}`, user).then(res => {
      dispatch(setUser(res.data));
    });
  }

  useAuthRedirect();
  return (
    <div className="mx-auto pt-0 lg:flex lg:gap-x-0 lg:pr-8">
      <main className="px-4 sm:px-6 lg:flex-auto lg:px-0 lg:py-0">
        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
          <h3 className="font-semibold leading-7 text-gray-600">Personal Information</h3>
          <div className="grid lg:grid-cols-2 md:grid-cols-1 lg:gap-8 md:gap-4">
            <dl className={commonClasses}>
              <UserInfoItem
                title="Email address"
                value={user?.email}
                handleChange={handleChange}
                handleSave={handleSave}
                name="email"
              />
              <UserInfoItem
                title="First name"
                value={user?.first_name}
                handleChange={handleChange}
                handleSave={handleSave}
                name="first_name"
              />
              <UserInfoItem
                title="Last name"
                value={user?.last_name}
                handleChange={handleChange}
                handleSave={handleSave}
                name="last_name"
              />
              <UserImage image={user?.image}/>
            </dl>
            <div className={commonClasses}>
              <h2 className="text-base font-semibold leading-7 text-gray-900">Additional info</h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">You have no information</p>

            </div>
            {/*<div className={commonClasses}>*/}
            {/*  <h2 className="text-base font-semibold leading-7 text-gray-900">Integrations</h2>*/}
            {/*  <p className="mt-1 text-sm leading-6 text-gray-500">Connect applications to your account.</p>*/}

            {/*  <ul role="list" className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">*/}
            {/*    <li className="flex justify-between gap-x-6 py-6">*/}
            {/*      <div className="font-medium text-gray-900">QuickBooks</div>*/}
            {/*      <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
            {/*        Update*/}
            {/*      </button>*/}
            {/*    </li>*/}
            {/*  </ul>*/}

            {/*  <div className="flex border-t border-gray-100 pt-6">*/}
            {/*    <button type="button" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">*/}
            {/*      <span aria-hidden="true">+</span> Add another application*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className={commonClasses}>*/}
            {/*  <h2 className="text-base font-semibold leading-7 text-gray-900">Language and dates</h2>*/}
            {/*  <p className="mt-1 text-sm leading-6 text-gray-500">*/}
            {/*    Choose what language and date format to use throughout your account.*/}
            {/*  </p>*/}

            {/*  <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">*/}
            {/*    <div className="pt-6 sm:flex">*/}
            {/*      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Language</dt>*/}
            {/*      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">*/}
            {/*        <div className="text-gray-900">English</div>*/}
            {/*        <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
            {/*          Update*/}
            {/*        </button>*/}
            {/*      </dd>*/}
            {/*    </div>*/}
            {/*    <div className="pt-6 sm:flex">*/}
            {/*      <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Date format</dt>*/}
            {/*      <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">*/}
            {/*        <div className="text-gray-900">DD-MM-YYYY</div>*/}
            {/*        <button type="button" className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
            {/*          Update*/}
            {/*        </button>*/}
            {/*      </dd>*/}
            {/*    </div>*/}
            {/*  </dl>*/}
            {/*</div>*/}
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserInfo;

