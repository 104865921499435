import {RESET_STATE, SET_CHARTS} from '../actions/types';

const initialState = {
  companyRevenue: [
    ["January", 0],
    ["February", 0],
    ["March", 0],
    ["April", 0],
    ["May", 0],
    ["June", 0],
    ["July", 0],
    ["August", 0],
    ["September", 0],
    ["October", 0],
    ["November", 0],
    ["December", 0],
  ],
  myPayroll: [
    ["Premium stops", 486.42],
    ["eCommerce stops", 567.62],
    ["Premium packages", 64.18],
    ["eCommerce packages", 51.85],
    ["Pickup stops", 22.78],
    ["Sunday stops", 132],
    ["Oversize", 63.36],
    ["Saturday stops", 159.5],
    ["Weekend bonus", 56.5],
    ["Attendance & cell phone", 12],
    ["Customer service", 67.25],
    ["Safety", 151.25],
  ],
  myIncome: [
    ["January", 0],
    ["February", 0],
    ["March", 0],
    ["April", 0],
    ["May", 0],
    ["June", 0],
    ["July", 0],
    ["August", 0],
    ["September", 0],
    ["October", 0],
    ["November", 0],
    ["December", 0],
  ],
}

const charts = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return initialState;
    case SET_CHARTS:
      return action.payload;
    default:
      return state;
  }
};

export default charts;