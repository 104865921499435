import React from "react";
import {useSelector} from 'react-redux';
import FacebookIcon from "../../components/icons/FacebookIcon";
import InstagramIcon from "../../components/icons/InstagramIcon";
import TwitterIcon from "../../components/icons/TwitterIcon";
import GitHubIcon from "../../components/icons/GitHubIcon";
import YouTubeIcon from "../../components/icons/YouTubeIcon";
import {Link} from "react-router-dom";

const Footer = () => {
  const {routes} = useSelector(state => state.config);
  const navigation = {
    main: [
      {name: 'About', href: routes.about},
      {name: 'Cookie Policy', href: routes.cookiePolicy},
      {name: 'Privacy Policy', href: routes.privacyPolicy},
      {name: 'Terms of service', href: routes.termsOfService},
      {name: 'Jobs', href: routes.jobs},
    ],
  }
  const social = [
      {
        name: 'Facebook',
        href: '#',
        icon: (props) => <FacebookIcon {...props} viewBox="0 0 240 240"/>
      },
      {
        name: 'Instagram',
        href: '#',
        icon: (props) => <InstagramIcon {...props} viewBox="0 0 2400 2400"/>
      },
      {
        name: 'Twitter',
        href: '#',
        icon: (props) => <TwitterIcon {...props} viewBox="0 0 240 240"/>
      },
      {
        name: 'GitHub',
        href: '#',
        icon: (props) => <GitHubIcon {...props} viewBox="0 0 240 240"/>,
      },
      {
        name: 'YouTube',
        href: '#',
        icon: (props) => <YouTubeIcon {...props} viewBox="0 0 12000 12000"/>,
      },
    ]

  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <Link to={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                {item.name}
              </Link>
            </div>
          ))}
        </nav>
        <div className="mt-10 flex justify-center space-x-10">
          {social.map((item) => (
            <Link key={item.name} to={item.href} target='_blank' className="text-gray-400 hover:text-blue-500">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true"/>
            </Link>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-gray-500">
          &copy; 2020 All Ground, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer;