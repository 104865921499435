import React, {Fragment} from "react";
import Roadmap from "./Roadmap";
import Header from "../header/Header";
import Footer from "../footer/Footer";

const RoadmapPage = () => {
  return (
    <Fragment>
      <Header/>
      <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 className="text-lg pb-10">Roadmap</h1>
        <Roadmap/>
      </section>
      <Footer/>
    </Fragment>
  )
}

export default RoadmapPage;