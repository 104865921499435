
const MessageDisplay = ({ message }) => {
  return message ? (
    <div className="p-6 bg-white rounded shadow flex justify-start items-center">
      <i className="text-gray-500 bi bi-chat-dots mx-2"/>
      <span className="relative flex h-3 w-3">
        <span
          className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-500"></span>
      </span>
      <span className="px-2 text-gray-600">{message}</span>
    </div>
  ) : (
    <div className="p-6 bg-white rounded shadow">
      <i className="text-gray-500 bi bi-chat-dots mx-2"/>
      <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
    </div>
  );
};

export default MessageDisplay;
