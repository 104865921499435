import React, {useState} from 'react';
import {BsEyeSlash, BsEye} from "react-icons/bs";

const Input = ({name, value, onChange, id, label, type = 'text', required = false, placeholder, disabled=false, autoComplete = null}) => {
  const _id = id || `floating_${name}`;
  const [inputType, setInputType] = useState(type === 'password' ? 'password' : null);
  placeholder = placeholder || name;
  return (
    <div className="h-fit relative w-full md:flex-1 my-4">
      {inputType && <button type="button" className="absolute right-3 top-5 text-gray-400"
                            onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')}>
        {inputType === 'password' && <BsEyeSlash className=""/>}
        {inputType === 'text' && <BsEye className="fill-pink-500"/>}
      </button>}
      <input type={inputType ? inputType : type} name={name} id={_id} value={value}
             onChange={onChange}
             onInput={onChange}
             className={`block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-500 peer active:bg-transparent disabled:text-gray-400`}
             placeholder=" " required={required} disabled={disabled} autoComplete={autoComplete}/>
      <label htmlFor={_id}
             className="z-10 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 origin-[0] peer-focus:left-0 peer-focus:text-blue-500 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
        {label || placeholder}
      </label>
    </div>
  );
};

export default Input;