import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import api from "../../../app/request";
import CreateCompanyRates from "./CompanyRate/CreateCompanyRates";
import {setCompanies, setCompanyRates, setUnits} from "../../../actions";
import CompaniesRatesTable from "./CompanyRate/CompaniesRatesTable";
import {useQuery} from "@tanstack/react-query";
import {fetchCompanies} from "../../hooks/queries";

const CompanyRatePage = () => {
  const dispatch = useDispatch();
  const companyRates = useSelector(state => state.companyRates);
  const companies = useSelector(state => state.companies);
  const urls = useSelector(state => state.config.urls);
  const units = useSelector(state => state.units);
  const template = useSelector(state => state.template);
  const [rates, setRates] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [preparedRates, setPreparedRates] = useState([]);

  const companiesData = useQuery({
    queryKey: ['companies'],
    queryFn: fetchCompanies,
    enabled: Boolean(!companies || companies.length === 0)
  });

  useEffect(() => {
    if (companies && (currentCompany === companies[companies.length - 1].id)) {
      companiesData.refetch().then(res => {
        if (res?.status === 200 && res?.data) dispatch(setCompanies(res.data));
      });
    } else if (companies && companies.length > 0 && !currentCompany) {
      setCurrentCompany(companies[0].id);
    }

  }, []);

  const handleChangeValue = (e) => {
    const {name, value} = e.target;
    const newRates = rates ? [...rates] : template;

    setRates(newRates.map(rate => {
      if (rate.name === name) {
        rate.value = value;
      }
      return rate;
    }));
  }

  const handleSubmit = (data) => {
    const currentRates = [...preparedRates, ...data]
    setPreparedRates(currentRates)
    const companyId = companies[companies.length - 1].id

    if (currentCompany === companyId) {
      api.post(`${urls.companyRates}${urls.bulk}`, currentRates).then(res => {
        if (res?.status === 200 && res?.data) {
          dispatch(setCompanyRates(res.data));
          api.get(`${urls.units}/`).then(res => {
            if (res?.status === 200 && res?.data) {
              setCurrentCompany(companies[0].id);
              dispatch(setUnits(res.data));
            }
          })
        }
      });
    } else {
      const currentIndex = companies.findIndex(company => company.id === currentCompany);
      const nextCompany = companies[currentIndex + 1];
      setCurrentCompany(nextCompany.id);
    }
  }

  return (
    <div className="flex flex-col min-h-screen">
      {!companyRates && companies &&
        <CreateCompanyRates companies={companies} rates={rates} setRates={setRates}
                            handleChangeValue={handleChangeValue}
                            currentCompany={currentCompany} handleSubmit={handleSubmit}/>}
      {companyRates && companies && companies.length > 0 &&
        <CompaniesRatesTable companyRates={companyRates} setCurrentCompany={setCurrentCompany}
                             companies={companies} currentCompany={currentCompany} units={units}/>}
    </div>
  );
};

export default CompanyRatePage;