import React from "react";
import IconWrapper from "./IconWrapper";

const TwitterIcon = ({viewBox, className = "w-3 h-3 text-white"}) => {
  return (
    <IconWrapper className={className} viewBox={viewBox}>
      <path d="M83 203C158 203 200 140 200 86V80a83 83 0 00 20-20 82 82 0 01-24 6 41 41 0 00 18-23 82 82 0 01-26 10 41 41 0 00-70 37 117 117 0 01-84-43 41 41 0 00 13 55 40 40 0 01-19-5 41 41 0 00 33 40 40 40 0 01-19 2 41 41 0 00 39 28A82 82 0 01 20 184a116 116 0 00 63 18"/>
    </IconWrapper>
  )
}

export default TwitterIcon;