import {RESET_STATE, SET_SETTINGS_MENU} from '../actions/types';

const initialState = [
  {name: 'Here can be settings button', link: '/settings/#', active: true},

]

const settingsMenu = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return initialState;
    case SET_SETTINGS_MENU:
      return action.payload;
    default:
      return state;
  }
};

export default settingsMenu;