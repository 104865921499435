import React from 'react';
import SupportForm from "./support/SupportForm";
import ContactInfo from "./support/ContactInfo";
import ManagerCard from "./support/ManagerCard";

const ProfileContactPage = () => {

  const managers = [
    {
      id: 1,
      name: 'Michael Tucci',
      title: 'Operations Director - SG',
      phone: '+1 (253) 441-0932',
      email: 'tucci@seattleground.com'
    },
    {
      id: 2,
      name: 'Ryancarlo Robles',
      title: 'Operations Director',
      phone: '+ 1 (206) 446-6974',
      email: 'ryan@groundaround.com'
    },
    {
      id: 3,
      name: 'Gig Todd',
      title: 'IT Director',
      phone: '+ 1 (808) 345-2315',
      email: 'gig@pugetground.com'
    },
    {
      id: 4,
      name: 'John Nguyen',
      title: 'Fleet Manager',
      phone: '+ 1 (253) 737-0108',
      email: 'johnn@groundaround.com'
    },
    {
      id: 5,
      name: 'Mark Fujimoto',
      title: 'Sr.  Operations Director',
      phone: '+1 (206) 406-6863',
      email: 'fuji@seattleground.com'
    },
    {
      id: 6,
      name: 'Stephen Palado',
      title: 'Route Manager',
      phone: '+1 (206) 778-9801',
      email: 'stephen_palado@yahoo.com'
    },
    {
      id: 7,
      name: 'Yusuf Mohamed',
      title: 'Operations Director - ECP',
      phone: '+1 (206) 602-4892',
      email: 'yim1976@msn.com'
    },
    {
      id: 8,
      name: 'Antony Todd',
      title: 'CEO - Puget Ground',
      phone: '+1 (360) 589-3550',
      email: 'tony@seattleground.com'
    }
  ]


  return (
    <div>
      <div className="relative isolate bg-white">
        <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
          <ContactInfo/>
          <SupportForm/>
        </div>
      </div>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
              <div>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Help Center
                </h2>
                <p className="mt-4 leading-7 text-gray-600">
                  Contact our management team with any questions, concerns, or feedback.
                </p>
              </div>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                {managers.map((manager) => (
                  <ManagerCard key={manager.id} {...manager}/>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContactPage;