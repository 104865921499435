import React from 'react';

const ParagraphAmount = ({name, amount}) => {
  return (
    <p className="pt-10 pb-4 mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
      You have {amount} {name} associated with your account
    </p>
  );
};

export default ParagraphAmount;