import React from "react";
import IconWrapper from "./IconWrapper";

const InstagramIcon = ({viewBox, className = "w-3 h-3 text-white"}) => {
  return (
    <IconWrapper className={className} viewBox={viewBox}>
      <path
        d="M1231 200c243 0 279 1 381 6 107 5 180 22 243 47a490 490 0 01 177 115 490 490 0 01 115 177c25 64 42 136 47 243 5 106 6 140 6 412v8c0 264-1 299-6 404a735 735 0 01-47 243 490 490 0 01-115 177 490 490 0 01-177 115 730 730 0 01-243 47c-106 5-140 6-412 6h-8a6960 6960 0 01-404-6 735 735 0 01-243-47 490 490 0 01-177-115 490 490 0 01-116-177 735 735 0 01-46-243 6460 6460 0 01-6-380v-63c0-243 0-279 6-381 5-107 22-180 46-243a490 490 0 01 116-177 490 490 0 01 177-116 735 735 0 01 243-46 6460 6460 0 01 380-6h64zm-8 180h-46c-246 0-279 1-381 6-98 4-150 20-186 34-47 19-80 40-115 75A310 310 0 00 420 610c-13 36-30 88-34 186a6464 6464 0 00-6 380v47c0 246 1 279 6 381 4 98 20 150 34 186a327 327 0 00 190 190c36 13 88 30 186 34 105 5 137 6 404 6h8c260 0 292-1 396-6a540 540 0 00 186-34 327 327 0 00 190-190c13-35 30-88 34-186 5-105 6-137 6-404v-8c0-260-1-292-6-396a540 540 0 00-34-186 310 310 0 00-75-115 310 310 0 00-115-75 560 560 0 00-186-34 6464 6464 0 00-380-6zM1200 687a513 513 0 11 0 1027 513 513 0 01 0-1028zm0 180a333 333 0 10 0 666 333 333 0 00 0-666zm534-320a120 120 0 11 0 240 120 120 0 01 0-240"
        clipRule="evenodd" fillRule="evenodd"/>
    </IconWrapper>
  )
}

export default InstagramIcon;