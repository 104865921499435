import React from 'react';

const Banner = ({content, onClose, className}) => {
  return (
    <div
      className="relative inset-x-0 bottom-0 flex flex-col justify-between gap-x-8 gap-y-4 bg-white p-6 ring-1 ring-gray-900/10 md:flex-row md:items-center lg:px-8 my-6">
      <p className={`max-w-4xl text-sm leading-6 ${className}`}>
        {content}
      </p>
      <div className="flex flex-none items-center gap-x-5">
        <button
          type="button" onClick={onClose}
          className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        >
          Close notice
        </button>
      </div>
    </div>
  );
};

export default Banner;