import React from 'react';
import SecuritySwitcher from "./security/SecuritySwitcher";
import SecurityUpdatePassword from "./security/SecurityUpdatePassword";

const Security = () => {
  const items = [
    {
      id: 1,
      name: 'Use cookie',
      description: 'Allow us to use cookies to make your experience better',
      isEnabled: true
    },
    {
      id: 2,
      name: 'Email notifications',
      description: 'Turn on email notifications for security alerts',
      isEnabled: false
    }
  ];
  return (
    <div className="grid grid-cols-1 gap-4">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-700 px-7">Security</h3>
      </div>
      <div className="px-8 py-4">
        {items.map(item => <SecuritySwitcher key={item.id} item={item} onChange={() => {}}/>)}
      </div>
      <SecurityUpdatePassword/>
    </div>
  );
};

export default Security;