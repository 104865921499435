import {combineReducers} from "redux";
import message from "./message";
import user from "./user";
import config from "./config";
import loading from "./loading";
import serverStatus from "./serverStatus";
import fedexId from "./fedexId";
import timeData from "./timeData";
import users from "./users";
import output from "./output";
import revenue from "./revenue";
import payrolls from "./payrolls";
import companies from "./companies";
import revenues from "./revenues";
import settingsMenu from "./settingsMenu";
import chosenDate from "./chosenDate";
import vir from "./vir";
import vehicles from "./vehicles";
import passedCompanyIds from "./passedCompanyIds";
import payrollsPlus from "./payrollsPlus";
import secrets from "./secrets";
import access_token from "./access_token";
import account from "./account";
import deliveryData from "./deliveryData";
import revenueDataset from "./revenueDataset";
import employees from "./employees";
import charts from "./charts";
import startDay from "./startDay";
import endDay from "./endDay";
import companyRates from "./companyRates";
import units from "./units";
import shiftTypes from "./shiftTypes";
import payStructures from "./payStructures";
import credentials from "./credentials";
import template from "./template";
import vacations from "./vacations";
import sickLeaves from "./sickLeaves";
import routes from "./routes";
import payTypes from "./payTypes";

export default combineReducers({
    access_token,
    account,
    config,
    user,
    message,
    loading,
    serverStatus,
    fedexId,
    timeData,
    users,
    output,
    revenue,
    payrolls,
    companies,
    revenues,
    settingsMenu,
    chosenDate,
    vir,
    vehicles,
    passedCompanyIds,
    payrollsPlus,
    secrets,
    deliveryData,
    revenueDataset,
    employees,
    charts,
    startDay,
    endDay,
    companyRates,
    units,
    shiftTypes,
    payStructures,
    credentials,
    template,
    vacations,
    sickLeaves,
    routes,
    payTypes,
});