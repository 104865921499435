import React from 'react';
import {Link} from "react-router-dom";


const NavItem = ({ item, Icon, keyVal, isFolded }) => (
  <li key={keyVal}>
    <Link to={item.href} className='text-gray-700 hover:text-blue-500 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'>
      <Icon className='text-gray-400 group-hover:text-blue-500 h-6 w-6 shrink-0' aria-hidden="true" />
      {!isFolded && <span>{item.name}</span>}
    </Link>
  </li>
);

export default NavItem;