import React from 'react';
import Header from "../features/header/Header";
import Footer from "../features/footer/Footer";
import LearnMoreHero from "./LearnMoreComponents/LearnMoreHero";
import LearnMoreStats from "./LearnMoreComponents/LearnMoreStats";
import LearnMoreFutures from "./LearnMoreComponents/LearnMoreFutures";
import LearnMoreLogos from "./LearnMoreComponents/LearnMoreLogos";
import LearnMoreSubscribe from "./LearnMoreComponents/LearnMoreSubscribe";

const LearnMoreLanding = () => {

  return (
    <div>
      <Header/>
      <LearnMoreHero/>
      <LearnMoreStats/>
      <LearnMoreFutures/>
      <LearnMoreLogos/>
      <LearnMoreSubscribe/>
      <Footer/>
    </div>
  );
};

export default LearnMoreLanding;