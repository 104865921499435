import React, {useState} from 'react';
import UserInfoEditButton from "./UserInfoEditButton";
import {BsShieldCheck} from "react-icons/bs";


const UserInfoItem = ({title, value, handleChange, handleSave, name}) => {
  const [edit, setEdit] = useState(false);

  return (
    <div className="pt-6 flex md:text-sm items-center">
      <BsShieldCheck className="fill-green-600 mr-1"/>
      {!edit && <dt className="font-bold text-xs text-gray-900 w-32 sm:flex-none sm:pr-6">{title}</dt>}
      <dd className="mt-1 flex text-xs justify-between lg:gap-x-6 md:gap-2 sm:mt-0 sm:flex-auto">
        {!edit && <div className="text-gray-900 w-full overflow-x-hidden">{value}</div>}
        {edit && <input type="text" value={value} name={name} className={`text-gray-700 ${edit ? ' border-b border-green-500 w-full' : ' w-20 border-b border-transparent'}`}
                        onChange={e => handleChange(name, e.target.value)}/>}
        {name !== 'email' && <UserInfoEditButton edit={edit} setEdit={setEdit} onSave={handleSave} value={value}/>}
      </dd>
    </div>
  )
};


export default UserInfoItem;