import React from 'react';
import Input from "../../../components/elements/Input";

const CredentialsForm = ({onChange, account, index}) => {
  const username = `username_${account.id}`;
  const password = `password_${account.id}`;
  return (
    <form key={account.id} className="grid md:grid-cols-12 md:gap-6">
      <div className="col-span-2 flex items-center">
        <span>{account.name}</span>
      </div>
      <div className="relative z-0 mb-6 group col-span-5">
        <Input
          name={username}
          id={username}
          value={account.username}
          onChange={(e) => onChange(e, index)}
          label="Enter FedEx Account"
          autoComplete={username}
        />
      </div>
      <div className="relative z-0 mb-6 group col-span-5">
        <Input
          type="password"
          name={password}
          id={password}
          value={account.password}
          onChange={(e) => onChange(e, index)}
          label="Enter FedEx Password"
          autoComplete={password}
        />
      </div>
    </form>
  );
};

export default CredentialsForm;
