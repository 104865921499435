import React, {useRef, useState} from "react";
import {FaMicrophoneAlt, FaSearch} from "react-icons/fa";

const SearchBar = ({placeholder = "Search...", handleSearchChange, data, searchQuery, setSearchQuery}) => {
  const [listening, setListening] = useState(false);
  const recognitionRef = useRef(null);

  const startListening = () => {
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.lang = 'en-US'; // language
      recognitionRef.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        if (transcript.toLowerCase() === "clear search") {
          setSearchQuery('');
        } else {
          setSearchQuery(transcript);
        }
      };
      recognitionRef.current.onresult = (event) => {
        setSearchQuery(event.results[0][0].transcript);
      };
      recognitionRef.current.onstart = () => setListening(true);
      recognitionRef.current.onend = () => setListening(false);
      recognitionRef.current.start();
    } else {
      alert('Sorry, your browser does not support speech recognition.');
    }
  };

  const stopListening = () => {

    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setListening(false);
    }
  };

  return (
    <div className="max-w-md mx-auto rounded-lg overflow-hidden md:max-w-xl">
      <div className="md:flex">
        <div className="w-full p-3">
          <div className="relative">
            <FaSearch className="absolute top-5 left-4 text-gray-400"/>
            <input
              type="text"
              placeholder={placeholder}
              value={searchQuery}
              onChange={handleSearchChange}
              className="bg-white h-14 w-full px-12 rounded-lg outline-none border-white"
            />
            <span className="absolute top-5 right-5 border-l pl-4">
                  <FaMicrophoneAlt
                    className={`text-gray-500 hover:text-green-500 cursor-pointer ${listening ? 'text-green-500' : ''}`}
                    onClick={listening ? stopListening : startListening}
                  />
              </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchBar;