import React from "react";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom";
import {Popover} from '@headlessui/react';
import {AnimatePresence, motion} from 'framer-motion';
import Logo from "../../components/icons/Logo";
import Container from "../../components/elements/Container";
import AuthButtons from "../../components/elements/AuthButtons";
import ProfileButtons from "../../components/elements/ProfileButtons";
import Notification from "../../components/elements/Notification";
import MenuIcon from "../../Icons/MenuIcon";
import ChevronUpIcon from "../../Icons/ChevronUpIcon";


function Header() {
  const {routes, d} = useSelector((state) => state.config);
  const user = useSelector((state) => state.user);

  return (
    <header>
      <nav>
        <Container className="relative flex justify-between py-4 z-50">
          <div className="relative z-10 flex items-center gap-16">
            <Link to={routes.home}>
              <Logo className="w-10"/>
            </Link>
          </div>
          <div className="flex items-center gap-6">
            <Popover className="lg:hidden">
              {({open}) => (
                <>
                  <Popover.Button
                    className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 [&:not(:focus-visible)]:focus:outline-none"
                    aria-label="Toggle site navigation"
                  >
                    {({open}) =>
                      open ? (
                        <ChevronUpIcon className="h-6 w-6"/>
                      ) : (
                        <MenuIcon className="h-6 w-6"/>
                      )
                    }
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{opacity: 0}}
                          animate={{opacity: 1}}
                          exit={{opacity: 0}}
                          className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{opacity: 0, y: -32}}
                          animate={{opacity: 1, y: 0}}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: {duration: 0.2},
                          }}
                          className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                        >
                          <div className="space-y-4">
                            {user && user.sudo && (
                              <Link to={routes.settings} className="text-blue-500 hover:text-blue-700">
                                {d.settings}
                              </Link>
                            )}
                          </div>
                          <div className="mt-8 flex flex-col gap-4">
                            {!user && <AuthButtons/>}
                            {user && <ProfileButtons/>}
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>
            {!user && <AuthButtons hidden="hidden lg:flex"/>}
            {user && <ProfileButtons hidden="hidden lg:flex"/>}
          </div>
        </Container>
      </nav>
    </header>
  )
}

export default Header;
