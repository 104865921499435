import React from 'react';
import {Chart as ReactChart} from "react-chartjs-2";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ArcElement,
  RadialLinearScale,
  PolarAreaController,
  PointElement,
} from 'chart.js';

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  RadialLinearScale,
  PolarAreaController,
  PointElement,
);

const getRandomLightColor = () => {
  const hue = Math.floor(Math.random() * 360);
  const saturation = 80 + Math.floor(Math.random() * 20); // 80% to 100%
  const lightness = 70 + Math.floor(Math.random() * 20); // 70% to 90%
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};


const Chart = ({data, label = 'Amount of $', type = 'pie'}) => {
  if (!data || data.length === 0) return null;

  const labels = data.map(row => row[0]);
  const values = data.map(row => row[1]);
  const backgroundColors = data.map(() => getRandomLightColor());

  const datasets = {
    label: label,
    data: values,
    backgroundColor: backgroundColors,
    borderColor: 'rgba(0,0,0,0.10)',
    border: 0.5,
  };

  const dataset = {
    labels: labels,
    datasets: [datasets]
  };

  return (
    <ReactChart type={type} data={dataset} width={100} height={50} options={{maintainAspectRatio: false}}/>
  );
};

export default Chart;