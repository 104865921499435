import React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format, parse } from 'date-fns';

const DateInput = ({ value, onChange, index, disabled }) => {
  const dateValue = value ? parse(value, "MM/dd/yyyy", new Date()) : null;
  const handleDateChange = (newDate) => {
    const formattedDate = newDate ? format(newDate, "MM/dd/yyyy") : '';
    onChange(formattedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={dateValue}
        onChange={handleDateChange}
        components={{
          TextField: (props) => (
            <TextField
              {...props}
              name={`date-${index}`}
              disabled={disabled}
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
