import React from 'react';
import Header from "../features/header/Header";
import Footer from "../features/footer/Footer";
import {useSelector} from "react-redux";


const OpenPositions = () => {
  const {jobs} = useSelector(state => state);
  return (
    <div>
      <Header/>
      <div className="container mx-auto px-4 min-h-screen">
        <div className="py-28">
          <h1 className="text-4xl font-semibold text-gray-700 py-4 text-center">Open Positions</h1>
          <div>
          {jobs && jobs.map((job, index) => (
            <div key={index} className="mt-8">
              <h2 className="text-2xl font-semibold text-gray-600 py-4">{job.title}</h2>
              <p className="mt-4 text-gray-500">{job.description}</p>
            </div>
          ))}
          {!jobs && (
            <div className="px-12">
              <p className="mt-24 text-gray-500">No open positions at this time.</p>
            </div>
          )}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default OpenPositions;