import React, {useEffect} from "react";
import {useQuery} from "@tanstack/react-query";
import Home from "./features/home/Home";
import Login from "./features/login/Login";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import RoadmapPage from "./features/roadmap";
import Account from "./features/account/Account";
import SignOut from "./features/user/SignOut";
import Profile from "./features/profile/Profile";
import LearnMoreLanding from "./pages/LearnMoreLanding";
import {setUser} from "./actions";
import {fetchRefreshToken} from "./features/hooks/queries";
import CookieNotice from "./components/CookieNotice";
import CookiePolicy from "./pages/CookiePolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import About from "./pages/About";
import OpenPositions from "./pages/OpenPositions";
import Notification from "./components/elements/Notification";


// Add styles
import "./tailwind.css";
import "./styles/main.scss";


function App() {
  const dispatch = useDispatch();

  const {routes, reFetchTime} = useSelector(state => state.config);


  const authData = useQuery({queryKey: ['refreshToken'],
    queryFn: fetchRefreshToken, refetchInterval: reFetchTime, enabled:true});

  useEffect(() => {
    if (authData?.data?.user) dispatch(setUser(authData.data.user));
  }, [dispatch, authData.data]);


  return (
    <>
      <Router>
        <Routes>
          <Route path={routes.home} element={<Home/>}/>
          <Route path={routes.login} element={<Login/>}/>
          <Route path={routes.about} element={<About/>}/>
          <Route path={routes.jobs} element={<OpenPositions/>}/>
          <Route path={routes.roadmap} element={<RoadmapPage/>}/>
          <Route path={routes.cookiePolicy} element={<CookiePolicy/>}/>
          <Route path={routes.privacyPolicy} element={<PrivacyPolicy/>}/>
          <Route path={routes.learnMore} element={<LearnMoreLanding/>}/>
          <Route path={routes.termsOfService} element={<TermsAndConditions/>}/>
          <Route path={`${routes.profile}/*`} element={<Profile/>}/>
          <Route path={`${routes.account}/*`} element={<Account/>}/>
          <Route path={routes.logout} element={<SignOut/>}/>
          <Route path="*" element={<div>Not found</div>}/>
        </Routes>
        <Notification/>
        <CookieNotice/>
      </Router>
    </>

  );
}

export default App;
