import React from "react";
import RoadmapBadge from "./RoadmapBadge";

const Roadmap = () => {
    const data = [
        {
            title: "AI-Powered Insights",
            date: "Upcoming",
            description: " Leverage the power of AI to understand payroll trends and anomalies. The app provides predictive analysis, helping you make data-driven decisions for your business.",
            implemented: false,
            is_current: false,
            start: false,
        },
        {
            title: "Mobile App Development",
            date: "Upcoming",
            description: "Get payroll done on-the-go with our mobile app. Whether you're commuting or traveling, our payroll app gives you the freedom to manage payroll from anywhere, anytime.",
            implemented: false,
            is_current: false,
            start: false,
        },
        {
            title: "Tax Compliance Features",
            date: "Upcoming",
            description: "Stay up-to-date and compliant with tax laws without the need for a tax consultant. The app automatically calculates and deducts the correct amount of tax based on the latest regulations.",
            implemented: false,
            is_current: false,
            start: false,
        },
        {
            title: "Subscription Service",
            date: "Upcoming",
            description: "This feature will allow users to choose a payroll plan that suits their needs. With various subscription tiers available, businesses of all sizes can find a package that matches their requirements. Once subscribed, enjoy uninterrupted payroll services, with automatic renewals and easy, secure payment methods. This means no more manual renewal, giving you more time to focus on your business.",
            implemented: false,
            is_current: false,
            start: false,
        },
        {
            title: "Integration with Time-Tracking Software",
            date: "Upcoming",
            description: "Easily sync your time-tracking software with our payroll app. It ensures accuracy in calculating hours worked and eliminates manual data entry, saving time and reducing errors.",
            implemented: false,
            is_current: false,
            start: false,
        },
        {
            title: "Document Upload Feature",
            date: "Upcoming",
            description: "Ability to upload documents for employees, eliminating the need to email paychecks. Just upload the documents directly to the employee's profile.",
            implemented: false,
            is_current: false,
            start: false
        },
        {
            title: "Automated Payroll Processing",
            date: "Upcoming",
            description: "The payroll process is fully automated and you can run it with a single click. Just login to your All Ground account with your credentials and you are ready to go.",
            implemented: false,
            is_current: false,
            start: false,
        },
        {
            title: "Manager Data Alteration Ability",
            date: "Upcoming",
            description: "Managers have the ability to alter and correct data that comprises payroll, such as the number of VIRs completed, etc.",
            implemented: false,
            is_current: false,
            start: false
        },
        {
            title: "Payroll Report Generation",
            date: "ETA July/August 2023",
            description: "Generate payroll reports for accounting and email them directly to accounting and managers from the app.",
            implemented: false,
            is_current: true,
            start: false
        },
        {
            title: "Automated FedEx Data Collection",
            date: "ETA July 2023",
            description: "Automatically gather essential information needed for payroll processing, thereby saving time and effort.",
            implemented: false,
            is_current: true,
            start: false
        },
        {
            title: "User Authentication Access",
            date: "Released June 2023",
            description: "Enhanced our system with a secure sign-in feature. This allows employees to access their compensation and payroll data with ease. With just a single click, they can log in using their secure Google accounts. This simplifies the process, eliminating the need for manual emails. It's a secure, efficient, and user-friendly update that will streamline the way employees interact with their payroll data.",
            implemented: true,
            is_current: false,
            start: false
        },
        {
            title: "Firebase Storage Integration",
            date: "Released on June 2023",
            description: "We've integrated Firebase Storage into our platform. This enhancement allows us to store and retrieve platform files efficiently and securely.",
            implemented: true,
            is_current: false,
            start: false,
        },
        {
            title: "New User Interface",
            date: "Released on April 2023",
            description: "Improved user interface with a new design, new features, and more. The new user interface is more user-friendly and easier to use.",
            implemented: true,
            is_current: false,
            start: false,
        },
        {
            title: "Started implementation of All Ground version 2.0.0",
            date: "Released on February 2023",
            description: "Started implementation of All Ground version 2.0.0. The new version will include a new user interface, new features, and more.",
            implemented: true,
            is_current: false,
            start: false,
        },
        {
            title: "Integrated manager functionality for payroll processing",
            date: "Released on January 2023",
            description: "In the user interface, managers can view and manage employee data, including the ability to add new employees, edit existing employees, and more.",
            implemented: true,
            is_current: false,
            start: false,
        },
        {
            title: "Implemented User Profile Functionality",
            date: "Released on December 2022",
            description: "Implemented user profile functionality, including the ability to manage user data, change passwords, and more.",
            implemented: true,
            is_current: false,
            start: false,
        },
        {
            title: "Developed core payroll functionality - All Ground version 1.0.0",
            date: "Released on September 2022",
            description: "Implemented core payroll functionality, including the ability to generate payrolls, manage employee data, and more.",
            implemented: true,
            is_current: false,
            start: false,
        },
        {
            title: "Created Backend API for All Ground",
            date: "Released on July 2022",
            description: "Implemented backend API and user authentication with security tokens.",
            implemented: true,
            is_current: false,
            start: false,
        },
        {
            title: "Built Script for FedEx Ground data cleaning",
            date: "Released on June 2022",
            description: "Created a Google App Script to clean FedEx Ground data and prepare it for payroll processing.",
            implemented: true,
            is_current: false,
            start: true,
        }
    ];

    return (
        <div className="pb-4">
            <ol className="relative border-l border-gray-200 dark:border-gray-700">
                {data.slice().reverse().map((entry, index) => (
                    <li key={index} className={`mb-10 ml-6 ${!entry.implemented ? 'text-gray-400' : 'text-gray-900'}`}>
                        <RoadmapBadge implemented={entry.implemented}/>
                        <h3 className="flex items-center mb-2 text-md font-semibold">
                            {entry.title}
                            {entry.is_current &&
                                <span
                                    className="bg-blue-500 text-white text-xs font-medium mr-2 px-3 py-0.5 rounded ml-4">in progress</span>}
                        </h3>
                        <time
                            className={`block mb-2 text-sm font-normal leading-none ${entry.implemented ? 'text-gray-400' : 'text-gray-300'}`}>
                            {entry.date}
                        </time>
                        <p className="mb-2 text-sm font-normal">{entry.description}</p>
                    </li>
                ))}
            </ol>
        </div>
    )
}

export default Roadmap;
