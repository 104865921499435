import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function useAuthRedirect() {
  const config = useSelector(store => store.config);
  const user = useSelector(store => store.user);

  const navigate = useNavigate();

  useEffect(() => {
    const {routes} = config;
    if (!user) {
      navigate(routes.login);
    }
  }, [user, navigate, config]);
}

export default useAuthRedirect;