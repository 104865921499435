import React, {Fragment} from 'react';
import {Dialog, Transition} from "@headlessui/react";
import {HiChevronLeft, HiOutlineCog8Tooth} from "react-icons/hi2";
import Logo from "../../../components/icons/Logo";
import NavItem from "./NavItem";
import LinkItem from "./LinkItem";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";


const ProfileHeader = ({sidebarOpen, setSidebarOpen, isSidebarFolded, navigation, links}) => {
  const home = useSelector(state => state.config.routes.home);
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80"/>
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-60 top-0 flex w-16 justify-center pt-5">
                  <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                    <span className="sr-only">Close sidebar</span>
                    <HiChevronLeft className="h-6 w-6 fill-gray-700 hover:fill-blue-500" aria-hidden="true"/>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <Link to={home} className="flex items-center">
                    <Logo className="h-14 w-auto" aria-hidden="true"/>
                  </Link>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul className="-mx-2 space-y-1">
                        {navigation.map((item) => item &&
                          <NavItem key={item.name} item={item} isFolded={isSidebarFolded}
                                   Icon={item.icon}/>)}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">Figures</div>
                      <ul className="-mx-2 mt-2 space-y-1">
                        {links.map(item => item?.display && <LinkItem key={item.name} item={item}/>)}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <Link
                        to="#"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-blue-500"
                      >
                        <HiOutlineCog8Tooth
                          className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-blue-500"
                          aria-hidden="true"
                        />
                        Settings
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ProfileHeader;