import React from 'react';
import {HiBars3, HiOutlineBell, HiOutlineChevronDown, HiOutlineMagnifyingGlass} from "react-icons/hi2";
import {Menu} from "@headlessui/react";
import ProfileHeaderMenu from "./ProfileHeaderMenu";
import {useSelector} from "react-redux";


const MobileSidebar = ({setSidebarOpen}) => {
  const user = useSelector(store => store.user);
  return (
    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 ml-6 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
        <span className="sr-only">Open sidebar</span>
        <HiBars3 className="h-6 w-6" aria-hidden="true"/>
      </button>

      {/* Separator */}
      <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true"/>

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <form className="relative flex flex-1" action="#" method="GET">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <HiOutlineMagnifyingGlass
            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
          <input
            id="search-field"
            className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search..."
            type="search"
            name="search"
          />
        </form>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-600">
            <span className="sr-only">View notifications</span>
            <HiOutlineBell className="h-6 w-6" aria-hidden="true"/>
          </button>

          {/* Separator */}
          <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true"/>

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              {user?.image && <img
                className="h-8 w-8 rounded-full bg-gray-50"
                src={user?.image}
                alt={`${user?.first_name} ${user?.last_name}`}
              />}
              {!user?.image && (
                <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                      <svg className="h-full w-full text-gray-400" fill="currentColor" viewBox="0 0 24 24">
                        <path
                          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                      </svg>
                    </span>
              )}
              <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {user?.first_name} {user?.last_name}
                      </span>
                      <HiOutlineChevronDown className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </span>
            </Menu.Button>
            <ProfileHeaderMenu/>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default MobileSidebar;