import {Fragment} from 'react';
import {Listbox, Transition} from '@headlessui/react';
import {HiCheck, HiChevronDown} from "react-icons/hi2";
import {classNames} from "../../../app/lib";


const Select = ({selected, onChange, options, name}) => {
  const handleChange = (e) => {
    const event = {
      target: {
        name: name,
        value: e
      }
    }
    console.log("event: ", event);
    onChange(event);
  }
  return (
    <Listbox value={selected} onChange={handleChange} name={name}>
      {({open}) => (
        <div className="relative mt-2">
          <Listbox.Button
            className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:text-sm sm:leading-6">
            <span className="block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <HiChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true"/>
              </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-50"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options style={{zIndex: 1000}}
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((item, index) => (
                <Listbox.Option
                  key={index}
                  className={({active}) =>
                    classNames(
                      active ? 'bg-blue-600 text-white' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-3 pr-9'
                    )
                  }
                  onChange={onChange}
                  value={item}
                >
                  {({selected, active}) => (
                    <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {item.name}
                        </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? 'text-white' : 'text-blue-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          )}
                        >
                            <HiCheck className="h-5 w-5" aria-hidden="true"/>
                          </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}


export default Select;