import React, { useEffect, useState } from 'react';
import { Loader } from "../../components/Loader";
import api from "../../app/request";
import {useDispatch, useSelector} from 'react-redux';
import {resetState} from "../../actions";
import { useNavigate } from 'react-router-dom';
import { auth } from "../../firebase";

const SignOut = () => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.config);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    api.post(config.urls.logout)
      .then(async res => {
        if (res.status === 200) {
          localStorage.removeItem('user');
          dispatch(resetState());
          await auth.signOut();
        }
        setLoading(false);
        navigate(config.routes.home);
      })
  }, [config.routes.home, config.urls.logout, dispatch, navigate]);


  return (
    <div className={'center Loader component in the center of the screen'}>
      {loading ? <Loader/> : null}
    </div>
  );
};

export default SignOut;
