import React from 'react';


const LearnMoreStats = () => {

  const stats = [
    {id: 1, name: 'Businesses Using Our App', value: '1,000+'},
    {id: 2, name: 'Average Time Saved', value: '95%'},
    {id: 3, name: 'Uptime Guarantee', value: '99.9%'},
    {id: 4, name: 'Client Satisfaction Rate', value: '95%'},
  ];

 return (
  <div className="relative isolate overflow-hidden py-24 sm:py-32 bg-black">
        <img
          src="https://images.unsplash.com/photo-1455165814004-1126a7199f9b?ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&amp;auto=format&amp;fit=crop&amp;w=1470&amp;q=80 1470w"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover blur-sm opacity-10"
        />
        <div className="relative mx-auto max-w-7xl px-6 lg:px-8 py-36">
          <div
            className="absolute -bottom-8 -left-96 -z-10 transform-gpu blur-3xl sm:-bottom-64 sm:-left-40 lg:-bottom-32 lg:left-8 xl:-left-10"
            aria-hidden="true"
          >
            <div
              className="aspect-[1266/975] w-[79.125rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
            <h2 className="mt-2 text-2xl font-bold tracking-tight text-white sm:text-4xl pb-8">
              Trusted by Businesses Across the Globe
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Our platform is trusted by thousands of businesses worldwide. We're committed to providing a reliable,
              efficient, and user-friendly solution for all your payroll needs. Our app is designed to simplify payroll
              management, giving you more time to focus on what matters most - growing your business.
            </p>
          </div>
          <dl
            className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col gap-y-3 border-l border-white/10 pl-6">
                <dt className="text-sm leading-6">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
 );
};

export default LearnMoreStats;