import React, {useState} from 'react';
import Chart from "./Chart";
import {useSelector} from "react-redux";
import LineButton from "../elements/LineButton";


const UnderstandPayroll = () => {
  const types = ["bar", "pie", "doughnut", "polarArea"]
  const [chartType, setChartType] = useState(types[0]);
  const myPayroll = useSelector(store => store.charts.myPayroll);

  return (
    <div className="h-[24rem] mb-24">
      <h1 className="text-xl text-gray-600 pb-8">Understand my payroll</h1>
      <div className="isolate flex ml-8">
        {types.map((type, index) => (
          <LineButton
            key={index}
            label={type}
            onClick={() => setChartType(type)}
            additionalClasses={`${type === chartType ? ' bg-gray-100' : ' bg-white'}`}
          />
        ))}
      </div>
      <Chart type={chartType} data={myPayroll}/>
    </div>
  );
};

export default UnderstandPayroll;