import React, {useEffect, useState} from 'react';
import api from "../../../../app/request";
import {useSelector} from "react-redux";
import {Loader} from "../../../../components/Loader";
import Input from "../../../../components/elements/Input";
import Button from "../../../../components/elements/Button";
import {FaRegMeh, FaRegSmile, FaRegFrown} from "react-icons/fa";
import {useNavigate} from "react-router-dom";


const SecurityUpdatePassword = () => {
  const navigate = useNavigate();
  const [matchPassword, setMatchPassword] = useState(null);
  const [lengthMet, setLengthMet] = useState(null);
  const [requirementsMet, setRequirementsMet] = useState(null);
  const [match, setMatch] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const user = useSelector(state => state.user);
  const {urls, routes} = useSelector(state => state.config);
  const [loading, setLoading] = useState(false);

  const getClassNames = (condition) => (password.length && confirmPassword.length) ?
    (condition ? "text-green-500" : "text-red-500") : "text-gray-200";

  const getIcon = (condition) => {
    const cls = 'w-4 h-4 mr-1'
    if (password.length === 0 || confirmPassword.length === 0) return <FaRegMeh className={cls}/>;
    return condition ? <FaRegSmile className={cls}/> : <FaRegFrown className={cls}/>;
  }

  const handleUpdatePassword = () => {
    if (!matchPassword) return;
    setLoading(true);
    user.password = password;
    setPassword("");
    setConfirmPassword("");
    api.put(`${urls.users}/${user.id}`, user)
      .then(res => {
        if (res?.status === 200 && res?.data) {
          console.log(res.data);
          return navigate(routes.profile);
        }
      })
      .finally(() => setLoading(false));
  }

  const handleChange = (e) => {
    const {name, value} = e.target;

    if (name === "password") setPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
  }

  useEffect(() => {
    const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const passwordsLength = password.length > 0 && confirmPassword.length > 0;
    setRequirementsMet(passwordsLength ? passwordRegex.test(password) : null);
    setLengthMet(passwordsLength ? (password.length >= 8 && confirmPassword.length >= 8) : null);
    setMatch(passwordsLength ? (password === confirmPassword) : null);

    setMatchPassword( (passwordsLength ? (password.length >= 8 && confirmPassword.length >= 8) : null)
      && (passwordsLength ? (password === confirmPassword) : null) && (passwordsLength ? passwordRegex.test(password) : null));
  }, [password, confirmPassword]);


  return (
    <div className="bg-white shadow sm:rounded-lg mx-4">
      <div className="px-4 py-5 sm:p-6 my-12">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Update password</h3>
        <div className="mt-2 max-w-xl text-sm text-gray-500">
          <p>Ensure your account is using a long, random password to stay secure.</p>
        </div>
        {!loading && <form className="mt-5 sm:flex sm:items-center">
          <div className="w-full flex md:space-x-3 flex-wrap">

            <Input name="password" value={password} onChange={handleChange} type="password"
                   label="Password" required={true}/>
            <Input name="confirmPassword" value={confirmPassword} onChange={handleChange}
                   type="password" label="Confirm Password" required={true}/>
            <Button
              type="button" disabled={!matchPassword} onClick={handleUpdatePassword}
              className={`${matchPassword ? "bg-blue-500 hover:bg-blue-600" : 
                "bg-gray-200 hover:bg-gray-200 cursor-not-allowed"} 
                sm:w-full lg:w-fit md:w-fit md:ml-4 inline-flex my-auto justify-center py-2 px-4 border border-transparent 
                shadow-sm text-sm font-medium rounded-md text-white sm:inline-block`}>
              Save
            </Button>

          </div>
        </form>}
        <div className="text-sm text-gray-500 mt-6">
          <p className={`${getClassNames(lengthMet)} flex items-center`}>
            {getIcon(lengthMet)} Password must be at least 8 characters long.
          </p>
          <p className={`${getClassNames(requirementsMet)} flex items-center`}>
            {getIcon(requirementsMet)} Password must contain at least one number, one special character, one lowercase
            letter, and one uppercase letter.
          </p>
          <p className={`${getClassNames(match)} flex items-center`}>
            {getIcon(match)} Passwords must match.
          </p>
        </div>
        {loading &&
          <div className="w-full flex justify-items-center items-center">
            <Loader/>
          </div>}
      </div>
    </div>
  );
};

export default SecurityUpdatePassword;