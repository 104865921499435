import {RESET_STATE, SET_ACCESS_TOKEN} from '../actions/types';

const access_token = (state = null, action) => {
  switch (action.type) {
    case RESET_STATE:
      return null;
    case SET_ACCESS_TOKEN:
      return action.payload;
    default:
      return state;
  }
};

export default access_token;
