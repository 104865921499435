import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../components/elements/Button";
import TimeSheetsToReview from "./TimeSheetsToReview";
import Modal from "../../../components/elements/Modal";
import Select from "./Select";
import DateInput from "../../../components/DateInput";
import Input from "../../../components/elements/Input";
import {setMessage} from "../../../actions";

const DisplayUnmatchedTime = ({data, onSubmit}) => {
  console.log("data: ", data);
  const dispatch = useDispatch();
  const employees = useSelector(state => state.employees);
  const companies = useSelector(state => state.companies);
  const shiftTypes = useSelector(state => state.shiftTypes);
  const startDay = useSelector(state => state.startDay);
  const endDay = useSelector(state => state.endDay);
  const [unmatched, setUnmatched] = useState([]);
  const [extraTime, setExtraTime] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [filteredShiftTypes, setFilteredShiftTypes] = useState(shiftTypes);
  const [localEmployees, setLocalEmployees] = useState([]);

  const [fields, setFields] = useState({shift_hours: ''});

  useEffect(() => {
    const newEmployees = employees.map(employee => {
      const name = employee.first_name + ' ' + employee.last_name;
      return {...employee, name: name};
    });
    setLocalEmployees(newEmployees);
    if (selectedCompany) {
      console.log("selectedCompany: ", selectedCompany);
      const filteredShiftTypes = shiftTypes.filter(shiftType => shiftType.company_id === selectedCompany.id);
      setFilteredShiftTypes(filteredShiftTypes);
    }

  }, [selectedCompany, employees]);

  useEffect(() => {
    const unmatchedTime = data.map((time, index) => {
      const employee = employees.find(employee => employee.id === time.employee_id);
      const company = companies.find(company => company.id === time.company_id);
      return {...time, employee, company, remove: false};
    });
    setUnmatched(unmatchedTime);
  }, [data, employees, companies]);

  const headers = ['employee', 'company', 'shift_date', 'shift_hours']

  const toggleRemove = (item) => {
    const newUnmatched = unmatched.map(time => {
      if (time.id === item.id) {
        return {...time, remove: !time.remove};
      }
      return time;
    });
    setUnmatched(newUnmatched);
  }


  const inputRef = useRef(null);

  const handleChange = (e) => {
    const {name, value} = e.target;
    if (name === 'company') setSelectedCompany(value);
    setFields({...fields, [name]: value});

  }

  const handleAdd = () => {
    if (!fields.employee || !fields.company || !fields.shift_hours || !fields.shift_type)
      return dispatch(setMessage({error: "Please fill all the fields"}));

    const newFields = {...fields, shift_date: inputRef?.current?.value};

    const newExtraTime = [...extraTime, newFields];
    setExtraTime(newExtraTime);
    setFields({shift_hours: ''});

    setOpenModal(false);
  }

  const handleRemove = (item) => {
    const newExtraTime = extraTime.filter(time => time.id !== item.id);
    setExtraTime(newExtraTime);
  }

  const filterDates = (date) => {
    const newStartDay = new Date(startDay);
    const newEndDay = new Date(endDay);
    const newDate = new Date(date);
    const result = newDate >= newStartDay && newDate <= newEndDay;
    if (!inputRef) inputRef.current.value = newDate.toDateString();
    return result;
  }

  const handleSubmit = () => {
    const dataToRemove = unmatched.filter(time => time.remove);
    const dataToSend = dataToRemove.map(time => time.composite_key);
    const toCreate = extraTime.map(time => {
      const {employee, company, shift_type, shift_hours, shift_date} = time;
      return {
        employee_id: employee.id,
        company_id: company.id,
        shift_type_id: shift_type.id,
        shift_hours: Number(shift_hours),
        shift_date
      }
    });
    return onSubmit({remove: dataToSend, create: toCreate});
  }

  return (
    <div>
      <TimeSheetsToReview unmatched={unmatched} headers={headers} toggleRemove={toggleRemove}/>
      <div className="my-6">
        <h2 className="text-xl w-fit inline-block">You can add extra time here</h2>
        <span>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded my-4 inline-block float-right"
            type="button" onClick={() => setOpenModal(true)}>Add row</button>
        </span>
        <table className="table-auto w-full text-center">
          <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="px-4 py-2">
                {header.split('_').join(' ').toUpperCase()}
              </th>
            ))}
            <th className="px-4 py-2">
              ADD/REMOVE
            </th>
          </tr>
          </thead>
          <tbody>
          {extraTime.map((time, index) => (
            <tr key={index}>
              {headers.map((header, index) => (
                <td key={index} className="border px-4 py-2">
                  {index === 0 && time[header].first_name + ' ' + time[header].last_name}
                  {index === 1 && time[header].name}
                  {index === 2 && new Date(time[header]).toLocaleDateString()}
                  {index === 3 && time[header]}
                </td>
              ))}
              <td onClick={() => handleRemove(time)}
                  className={`text-white font-bold py-2 px-4 rounded cursor-pointer${time.remove ? ' bg-green-800' : ' bg-red-700'}`}>
                {time.remove ? 'Add' : 'Remove'}
              </td>
            </tr>
          ))}
          </tbody>
        </table>
        <Modal open={openModal} setOpen={setOpenModal}>
          <div className="my-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee">Employee</label>
            <Select name="employee" id="employee" onChange={handleChange}
                    selected={fields.employee || {name: 'Select Employee'}} options={localEmployees}/>
          </div>
          <div className="my-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company">Company</label>
            <Select name="company" id="company" onChange={handleChange}
                    selected={fields.company || {name: 'Select Company'}} options={companies}/>
          </div>
          <div className="my-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="shift_date">Shift Type</label>
            <Select name="shift_type" id="shift_type" onChange={handleChange}
                    selected={fields.shift_type || {name: 'Select Shift Type'}} options={filteredShiftTypes}/>
          </div>
          <div className="my-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="shift_date">Shift Date</label>
            <DateInput name="shift_date" id="shift_date" filter={filterDates} inputRef={inputRef}/>
          </div>
          <div className="my-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="shift_hours">Shift Hours</label>
            <Input value={fields.shift_hours} name="shift_hours" id="shift_hours" onChange={handleChange}/>
          </div>
          <div className="my-4">
            <Button className="mt-4 max-w-[50hv]" content="Submit" onClick={handleAdd}>Add</Button>
          </div>
        </Modal>
      </div>
      <Button className="mt-4 max-w-[50hv]" content="Submit" onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default DisplayUnmatchedTime;