import {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {BsXLg} from "react-icons/bs";
import AvatarEditor from 'react-avatar-editor';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from "firebase/storage";
import {storage} from "../../../firebase";
import {useDispatch, useSelector} from "react-redux";
import api from "../../../app/request";
import {setUser} from "../../../actions";
import {Loader} from "../../../components/Loader";
import {compressImage} from "../../../app/lib";

const ImageEditorModal = ({image, setImage}) => {
  const buttonClasses = "rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-28 hover:text-gray-500 hover:ring-gray-200";
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const user = useSelector(state => state.user);
  const config = useSelector(state => state.config);
  const [scale, setScale] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setImage(null);
  }

  const handleSave = async () => {
  const storageRef = ref(storage, `images/user/${user.email}/profile.jpg`);

  // Check if file exists in storage and delete it
  try {await deleteObject(storageRef);} catch (error) {}

  setLoading(true);

  // Get the cropped and scaled image as a canvas
  const canvas = editorRef.current.getImageScaledToCanvas();

  // Convert the canvas to a Blob object
  canvas.toBlob(async (blob) => {
    // Compress the Blob using browser-image-compression
    const compressedBlob = await compressImage(blob);

    // Convert the Blob back to a File
    const file = new File([compressedBlob], 'profile.jpg', { type: 'image/jpeg' });

    // Upload the file to Firebase Storage
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          user.image = downloadURL;
          api.put(`${config.urls.users}/${user.id}`, user).then(res => {
            dispatch(setUser(res.data));
            localStorage.setItem('user', JSON.stringify(res.data));
          }).finally(() => {
            setLoading(false);
            handleClose();
          });
        });
      }
    );
  }, 'image/jpeg');
}

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
      const newScale = scale + e.deltaY * -0.01;
      // Restrict scale
      setScale(Math.min(Math.max(.1, newScale), 2));
    }

    window.addEventListener('wheel', handleWheel, {passive: false});

    // Clean up on unmount
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [scale]);

  return (
    <Transition.Root show={image !== null} as={Fragment}>
      <Dialog as="div" className="relative z-9999" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" style={{zIndex: 99}}/>
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto" style={{zIndex: 99}}>

          <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >

              <Dialog.Panel
                className="relative transform overflow-hidden w-fit rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm p-8">
                <button type="button" onClick={handleClose}
                        className="absolute top-0 right-0 m-4 hover:text-blue-500">
                  <BsXLg className="w-6"/>
                </button>
                {!loading && <AvatarEditor
                  className="m-6"
                  ref={editorRef}
                  style={{width: '20rem', height: '20rem'}}
                  image={image}
                  width={250}
                  height={250}
                  border={50}
                  color={[255, 255, 255, 0.7]}
                  scale={scale}
                  rotate={0}
                  borderRadius={180}
                />}
                {loading && (
                  <Loader />
                )}
                <div className="flex justify-between">
                  <button type="button" onClick={handleSave} className={buttonClasses}>Save</button>
                  <button type="button" onClick={handleClose} className={buttonClasses}>Cancel</button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ImageEditorModal;
