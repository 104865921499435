import React from 'react';

const ParagraphMissingData = ({name}) => {
  return (
    <p className="mx-auto pt-10 max-w-2xl text-base font-semibold leading-6 text-gray-700 lg:mx-0 lg:max-w-none">
      It looks like you don't have any {name} yet. Let's get started on that!
    </p>
  );
};

export default ParagraphMissingData;