import React from "react";
import IconWrapper from "./IconWrapper";

const YouTubeIcon = ({viewBox, className = "w-3 h-3 text-white"}) => {
  return (
    <IconWrapper className={className} viewBox={viewBox}>
      <path d="M2170 2300c-830 70-1460 750-1500 1580-40 690-70 1490-70 2040s30 1360 70 2050c40 830 650 1500 1480 1580a42260 42260 0 00 7690 0c830-70 1450-750 1490-1580 40-740 70-1580 70-2050 0-480-30-1300-70-2030-40-840-670-1520-1510-1590a49240 49240 0 00-7650 0zm2750 2010 2780 1610-2780 1600" fillRule="evenodd"/>
    </IconWrapper>
  )
}

export default YouTubeIcon;