import React, {useEffect, useState} from "react";

import {
  HiOutlineSquares2X2,
  HiOutlineDocumentChartBar,
  HiOutlineFingerPrint,
  HiOutlineUserCircle,
  HiOutlineArrowPath,
  HiOutlineBuildingOffice2,
  HiOutlineArrowLeftOnRectangle,
} from "react-icons/hi2";

import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useNavigate} from "react-router-dom";
import MobileSidebar from "../profile/elements/MobileSidebar";
import ProfileSidebar from "../profile/elements/ProfileSidebar";
import ProfileHeader from "../profile/elements/ProfileHeader";
import ManagerDashboard from "./pages/ManagerDashboard";
import Management from "./pages/Management";

import CompaniesPage from "./pages/CompaniesPage";
import VehiclePage from "./pages/VehiclePage";
import EmployeesPage from "./pages/EmployeesPage";
import {BsCurrencyDollar, BsPeople, BsTruck} from "react-icons/bs";
import CompanyRatePage from "./pages/CompanyRatePage";
import ShiftTypesPage from "./pages/ShiftTypesPage";
import PayrollsPage from "./pages/PayrollsPage";
import PayrollPage from "./pages/Payrolls/PayrollPage";
import DataManager from "./DataManager";


const Account = () => {

  // redux state
  const config = useSelector((store) => store.config);
  const companies = useSelector((store) => store.companies);

  // state variables
  const {routes} = config;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSidebarFolded, setSidebarFolded] = useState(false);
  const [links, setLinks] = useState([]);

  const navigation = [
    {
      name: "My profile",
      href: routes.profile,
      icon: HiOutlineUserCircle,
      current: false,
    },
    {
      name: "Dashboard",
      href: routes.account,
      icon: HiOutlineSquares2X2,
      current: true,
    },
    {
      name: "Processing",
      href: `${routes.account}${routes.management}`,
      icon: HiOutlineArrowPath,
      current: false,
    },
    {
      name: "Security",
      href: `${routes.account}${routes.security}`,
      icon: HiOutlineFingerPrint,
      current: false,
    },
  ];
  useEffect(() => {
    setLinks([
      {
        id: 1,
        name: "Companies",
        href: `${routes.account}${routes.companies}`,
        icon: HiOutlineBuildingOffice2,
        current: false,
        display: true,
      },
      {
        id: 2,
        name: "Vehicles",
        href: `${routes.account}${routes.vehicles}`,
        icon: BsTruck,
        current: false,
        display: companies && companies.length > 0,
      },
      {
        id: 3,
        name: "Employees",
        href: `${routes.account}${routes.employees}`,
        icon: BsPeople,
        current: false,
        display: companies && companies.length > 0,
      },
      {
        id: 4,
        name: "Company Rates",
        href: `${routes.account}${routes.companyRates}`,
        icon: BsCurrencyDollar,
        current: false,
        display: companies && companies.length > 0,
      },
      {
        id: 5,
        name: "Payrolls",
        href: `${routes.account}${routes.payrolls}`,
        icon: HiOutlineDocumentChartBar,
        current: false,
        display: true,
      },
      {
        id: 6,
        name: "Shift Types",
        href: `${routes.account}${routes.shiftTypes}`,
        icon: HiOutlineDocumentChartBar,
        current: false,
        display: companies && companies.length > 0,
      },
      {
        id: 7,
        name: "Sign out",
        href: routes.logout,
        icon: HiOutlineArrowLeftOnRectangle,
        current: false,
        display: true,
      },
    ])
  }, [companies]);

  return (
    <DataManager>
    <div>
      <ProfileHeader
        links={links}
        navigation={navigation}
        isFolded={isSidebarFolded}
        setFolded={setSidebarFolded}
        sidebarOpen={sidebarOpen}
        isSidebarFolded={isSidebarFolded}
        setSidebarOpen={setSidebarOpen}
      />
      <ProfileSidebar
        navigation={navigation}
        links={links}
        isFolded={isSidebarFolded}
        setFolded={setSidebarFolded}
      />
      <div className={`${isSidebarFolded ? "lg:ml-36" : "lg:pl-72"}`}>
        <MobileSidebar setSidebarOpen={setSidebarOpen}/>
        <div className="py-7">
          <Routes>
            <Route path={`${routes.management}`} element={<Management/>}/>
            <Route path={`${routes.companies}`} element={<CompaniesPage/>}/>
            <Route path={`${routes.vehicles}`} element={<VehiclePage/>}/>
            <Route path={`${routes.employees}`} element={<EmployeesPage/>}/>
            <Route path={`${routes.companyRates}`} element={<CompanyRatePage/>}/>
            <Route path={`${routes.shiftTypes}`} element={<ShiftTypesPage/>}/>
            <Route path={`${routes.payrolls}`} element={<PayrollsPage/>}/>
            <Route path={`${routes.payrolls}/:payrollId`} element={<PayrollPage/>}/>
            <Route path="*" element={<ManagerDashboard/>}/>
          </Routes>
        </div>
      </div>
    </div>
    </DataManager>
  );
};

export default Account;
