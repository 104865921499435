import React from 'react';
import {Link} from "react-router-dom";


const LinkItem = ({ item }) => (
  <li key={item.name}>
    <Link to={item.href} className='text-gray-700 hover:text-blue-500 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'>
      <span className='text-gray-400 border-gray-200 group-hover:border-blue-500 group-hover:text-blue-500 flex h-6 w-6 shrink-0 items-center justify-center text-[0.625rem] font-medium'>
        <item.icon className='h-4 w-4' aria-hidden="true" />
      </span>
      <span className="truncate">{item.name}</span>
    </Link>
  </li>
);

export default LinkItem;