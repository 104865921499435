import React, {useEffect} from 'react';
import Header from "../features/header/Header";
import Footer from "../features/footer/Footer";
import { getDatabase, ref, onValue } from "firebase/database";
import {app} from "../firebase";

const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = React.useState(null);

  useEffect(() => {
    const storedPolicy = localStorage.getItem('termsAndConditions');
    if (storedPolicy !== 'undefined' && storedPolicy !== null) {
      setTermsAndConditions(JSON.parse(storedPolicy));
    } else {
      const db = getDatabase(app);
      const policyRef = ref(db, '/pages/terms-and-conditions');
      onValue(policyRef, (snapshot) => {
        const data = snapshot.val();
        setTermsAndConditions(data);
      }, {
        onlyOnce: true,
      });
    }
  }, []);


  return (
    <div>
      <Header/>
      <div className="container mx-auto px-4">
        <div className="py-20">
          <h1 className="text-4xl font-semibold text-gray-700 py-4">{termsAndConditions?.title}</h1>
          {termsAndConditions?.sections.map((section, index) => (
            <div key={index} className="mt-8">
              <h2 className="text-2xl font-semibold text-gray-600 py-4">{section.title}</h2>
              {Array.isArray(section.content) ? section.content.map((subSection, subIndex) => (
                <div key={subIndex}>
                  <h3 className="text-xl font-semibold text-gray-600 py-2">{subSection.title}</h3>
                  <p className="mt-2 text-gray-500">{subSection.content}</p>
                </div>
              )) : <p className="mt-4 text-gray-500">{section.content}</p>}
            </div>
          ))}
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default TermsAndConditions;
