import {RESET_STATE, SET_CREDENTIALS} from '../actions/types';

const credentials = (state = null, action) => {
  switch (action.type) {
    case RESET_STATE:
      return null;
    case SET_CREDENTIALS:
      return action.payload;
    default:
      return state;
  }
};

export default credentials;