import React, {useCallback} from 'react';
import {BsFiletypeCsv, BsFiletypeXlsx} from "react-icons/bs";
import {useDropzone} from 'react-dropzone';
import api from "../../app/request";
import {useDispatch} from "react-redux";
import {setMessage} from "../../actions";
import {useQueryClient} from '@tanstack/react-query';

const UploadInput = ({setData, url, type = "csv"}) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const onFileChange = file => {
    if (file) {
      if (url) {
        const formData = new FormData();
        formData.append('file', file);

        api.post(url, formData)
          .then(res => {
            if (res?.status === 200 && res?.data && res?.data?.length > 0)
              dispatch(setData(res.data));

            if (res?.response?.status === 400 && res?.response?.data?.error) {
              dispatch(setMessage(res.response.data));
            }
          }).catch(err => {
            if (err?.data.error) dispatch(setMessage(err.data));
          console.log(err)
        }).finally(() => {
          queryClient.invalidateQueries();
          queryClient.clear();
          queryClient.refetchQueries();
        });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          const arrayBuffer = reader.result;
          setData(arrayBuffer);
        };
        reader.readAsArrayBuffer(file);
      }
    }
  };
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      onFileChange(acceptedFiles[0]);
    }
  }, [onFileChange]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <div className="flex items-center justify-center w-full my-14">
      <div {...getRootProps()}
           className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
        <input {...getInputProps()} />
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          {type === 'csv' && <BsFiletypeCsv className="text-6xl m-6 fill-gray-500"/>}
          {type === 'xlsx' && <BsFiletypeXlsx className="text-6xl m-6 fill-gray-500"/>}
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400">{type.toUpperCase()}</p>
        </div>
      </div>
    </div>
  );
};

export default UploadInput;
