import {RESET_STATE, SET_PASSED_COMPANY_IDS} from "../actions/types";

const passedCompanyIds = (state = [], action) => {
  switch (action.type) {
    case RESET_STATE:
      return [];
    case SET_PASSED_COMPANY_IDS:
      return action.payload;

    default:
      return state;
  }
};

export default passedCompanyIds;

