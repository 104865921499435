import React, {useEffect, useState} from 'react';
import api from "../../../../app/request";
import {useDispatch, useSelector} from "react-redux";
import {setTemplate} from "../../../../actions";


const CreateCompanyRates = ({companies, currentCompany, handleChangeValue, handleSubmit}) => {
  const dispatch = useDispatch();
  const [company, setCompany] = useState(null);
  const urls = useSelector(state => state.config.urls);
  const template = useSelector(state => state.template);
  const [rates, setRates] = useState(null);

  useEffect(() => {

    api.get(`${urls.companyRates}${urls.template}`).then(templateData => {
      if (templateData?.status === 200 && templateData?.data) {
        dispatch(setTemplate(templateData.data));
        setRates(templateData.data)
      }
    });


    if (companies && companies.length > 0) {
      setCompany(companies.find(company => company.id === currentCompany));
    }
  }, [companies, currentCompany]);

  const prepareRates = () => {
    const current = companies.find(company => company.id === currentCompany);
    setCompany(current);
    const companyRates = template.map(rate => ({
      company_id: currentCompany,
      name: rate.name,
      value: rate.value,
      unit: rate.unit,
      code: rate.code,
    }));
    handleSubmit(companyRates);
    window.scrollTo(0, 0);
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    const newRates = rates ? [...rates] : template;

    setRates(newRates.map(rate => {
      if (rate.name === name) {
        rate.value = value;
      }
      return rate;
    }));
  }

  return (
    <div>
      <h1 className="text-center text-2xl py-6 text-gray-600">
        {company && (
          <>Rates for <strong>{company?.name}</strong></>
        )}

        {!company && currentCompany && 'Submit companies rates?'}
      </h1>
      {companies && companies.map(company => {
        if (currentCompany !== company.id) return null;
        else return <div key={company.id}>
          <table className="table-auto min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">description</th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">rate</th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">unit</th>
            </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
            {rates && rates?.map((rate, index) => {

              return (
                <tr key={index}>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {rate.name.replace(/_/g, ' ')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <input className="border border-gray-300 rounded-md" type="text"
                           name={rate.name} value={rate.value} onInput={handleChangeValue}
                           onChange={handleChange}/>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {rate.unit}
                  </td>
                </tr>
              );
            })}

            </tbody>
          </table>
        </div>
      })}
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mr-10"
              type="button" onClick={prepareRates}>Submit
      </button>
    </div>
  );
};

export default CreateCompanyRates;