import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import UploadInput from "../../../components/elements/UploadInput";
import {parseXlsx} from "../../../app/lib";
import {setMessage} from "../../../actions";
import {useNavigate} from 'react-router-dom';

const UploadTimeData = ({sendData}) => {
  const dispatch = useDispatch();
  const companies = useSelector(store => store.companies);
  const employees = useSelector(store => store.employees);
  const shiftTypes = useSelector(store => store.shiftTypes);
  const [timeData, setTimeData] = useState([]);
  const [absenceHours, setAbsenceHours] = useState([]); // [{employee_id, company_id, absence_type, date, absence_hours}
  const [currentCompanyIndex, setCurrentCompanyIndex] = useState(0);

  const handleUpload = (arrayBuffer, company) => {

    if (!shiftTypes) return;
    const currentCompanyShiftTypes = shiftTypes.filter(shiftType => shiftType.company_id === company.id);
    const data = parseXlsx(arrayBuffer);
    const timeSheet = data['All Employees'];
    const header = timeSheet[0];
    const indexMap = header.map((item, index) => ({[item]: index})).reduce((acc, cur) => ({...acc, ...cur}), {});

    const shiftTypesNames = currentCompanyShiftTypes.map(shiftType => shiftType.name);
    const absenceNames = ['vacation', 'sickleave'];
    for (let i = 1; i < timeSheet.length; i++) {
      const uid = timeSheet[i][indexMap['Company ID']];
      const employee = employees.find(employee => employee.employee_id === uid.toString());

      if (!employee) {
        const firstName = timeSheet[i][indexMap['First name']];
        const lastName = timeSheet[i][indexMap['Last name']];
        const msg = `Employee with id ${uid} and name ${firstName} ${lastName} not found`;
        return dispatch(setMessage({error: msg}));
      }

      const job = timeSheet[i][indexMap['Job']];
      if (shiftTypesNames.includes(job.toLowerCase())) {

        const shift_type = currentCompanyShiftTypes.find(shiftType => shiftType.name === job.toLowerCase());
        const shift_hours = timeSheet[i][indexMap['Shift hours']];
        const shift_date = timeSheet[i][indexMap['Start Date']];

        const timeDataObj = {
          employee_id: employee.id,
          company_id: company.id,
          shift_hours: shift_hours,
          shift_date: shift_date,
          shift_type_id: shift_type.id,
        };

        setTimeData(prev => [...prev, timeDataObj]);
      } else if (absenceNames.includes(job.toLowerCase().replace(' ', '').trim())) {
        const absenceHoursObj = {
          employee_id: employee.id,
          company_id: company.id,
          absence_type: timeSheet[i][indexMap['Job']],
          date: timeSheet[i][indexMap['Start Date']],
          hours_used: timeSheet[i][indexMap['Absence Hours']],
        }
        setAbsenceHours(prevAbsenceHours => [...prevAbsenceHours, absenceHoursObj]);

      } else if (job && job.length > 0 && job !== 'Non Paid Absence') {
        const msg = `Unknown job type: ${job} for company ${company.name}`;
        dispatch(setMessage({error: msg}));
        console.log('Unknown job type: ', job);
      } else {
        console.log("job was empty: ", job, "for company: ", company?.name, "employee: ", employee?.name);
      }

    }
    setCurrentCompanyIndex(currentCompanyIndex + 1); // Move to the next company after upload
  }

  const handleSendData = () => {
    const data = {
      time_data: timeData,
      absence: absenceHours
    }

    sendData(data);
  }

  const handleSkipCompany = () => {
    setCurrentCompanyIndex(currentCompanyIndex + 1);
  }
  return (
    <div>
      {companies.map((company, index) => {
        if (index === currentCompanyIndex) {
          return (
            <div key={company.id} className="animate-[fade-in-up_1s_ease-in-out]">
              <h1 className="text-center text-2xl pt-8">Please upload time sheets for {company.name}</h1>

              <UploadInput setData={(buffer) => handleUpload(buffer, company)} type={"xlsx"}/>
              <button
                className="bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-2 px-5 rounded w-full"
                onClick={handleSkipCompany}>Skip {company.name}</button>
            </div>
          );
        } else {
          return null;
        }
      })}
      {currentCompanyIndex === companies.length && <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-12"
        onClick={handleSendData}>Send Data</button>
      }
    </div>
  );
};

export default UploadTimeData;
