import imageCompression from 'browser-image-compression';
import Papa from 'papaparse';
import {read, utils} from 'xlsx';

export const parseXlsx = (arrayBuffer) => {
  const data = new Uint8Array(arrayBuffer);
  const workbook = read(data, { type: 'array' });

  const result = {};
  workbook.SheetNames.forEach((sheetName) => {
    const roa = utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
    if (roa.length) result[sheetName] = roa;
  });

  return result;
};


export const parseCsv = (csvData) => {
  const result = Papa.parse(csvData, { header: true });
  return result.data;
}

export function keyToString(str) {
  return str.split('_').map((e,) => capitalize(e)).join(' ')
}

export function getBaseUrl() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  if (!baseUrl) return 'https://api.allground.io'
  else return baseUrl
}

export function dateToString(date) {
  return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function arrayToObject(arr) {
  const result = {};
  arr.forEach((s, i) => {
    result[s] = i
  });
  return result;
}

export function sum(arr) {
  return Math.round(arr.reduce((partialSum, a) => partialSum + a, 0)).toFixed(2);
}

export const getCompany = (company_id, companies) => companies.find(c => c.id === company_id);

export const getUser = (uid, users) => users.find(user => user.id === uid);


export const randomStr = (length) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export const getDate = dateStr => {
  const date = new Date(dateStr);
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}
export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
};

export const compressImage = file => {
  const options = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 600,
    useWebWorker: true,
    fileType: 'image/jpeg',
    initialQuality: 0.7,
  };
  return imageCompression(file, options);
}


export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}