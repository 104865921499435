import React from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import CompanyRevenue from "../../../components/charts/CompanyRevenue";



const ManagerDashboard = () => {
  const companies = useSelector(store => store.companies);
  const {routes} = useSelector(store => store.config);

  return (
    <div className="px-12">
      {(!companies || companies.length === 0) && <div className="flex flex-wrap justify-center items-center h-96">
        <h1 className="text-2xl text-gray-600 text-center block w-full">
          You have no companies yet. Please create one first.
        </h1>
        <Link to={`${routes.account}${routes.companies}`}
              className="bg-blue-500 hover:bg-gray-700 text-white py-2 px-3 rounded-lg shadow-lg hover:shadow block">
          Create Company
        </Link>
      </div>}
      {(companies && companies.length > 0) ? (
        <CompanyRevenue />
      ) : null}
    </div>
  );
};

export default ManagerDashboard;