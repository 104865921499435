import React from "react";

const Button = ({children, onClick, className, disabled, type="button"}) => {
    return (
    <button onClick={onClick} type={type}
            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:bg-blue-600 dark:active:bg-blue-600 w-full ${className}`}
            disabled={disabled}>
      <span className='mx-auto'>{children}</span>
    </button>
  );
}

export default Button;