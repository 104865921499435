import Axios from 'axios';
import {getBaseUrl} from "./lib";
import store from "./store";
import {resetState, setLoading} from "../actions";

const api = Axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
});


api.interceptors.request.use(config => {

  store.dispatch(setLoading(true));
  return config;
}, function (error) {
  // Do something with request error
  console.error('error: ', error)
  store.dispatch(setLoading(false));
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
  // Return response data
  store.dispatch(setLoading(false));

  return response;
}, async error => {
  const msg = "Your session has expired. Please login again.";
  if (error?.response?.status === 401) {
    store.dispatch(resetState());
    return Promise.resolve(error);
  }
  if (error?.response?.status === 403) {
    if (error?.response?.data?.error === 'Refresh token is invalid' ||
      error?.response?.data?.error === 'Refresh token is expired' ||
      error?.response?.data?.error === 'Refresh token is missing') {
      localStorage.removeItem('user');
      store.dispatch(resetState());
    }
  console.log('error: ', error.response)
  }
  return Promise.resolve(error);
});

export default api;



