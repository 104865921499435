import React, {useEffect, useState} from 'react';
import Table from "../../../components/elements/Table";
import {useSelector} from "react-redux";
import {capitalize} from "../../../app/lib";

const ShiftTypesTable = () => {
  const shiftTypes = useSelector(state => state.shiftTypes);
  const payStructures = useSelector(state => state.payStructures);
  const companies = useSelector(state => state.companies);

  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (shiftTypes && shiftTypes.length > 0) {
      const headers = [
        'Name',
        'Company',
        'Pay Structure',
        ];
      const rows = shiftTypes?.map(shiftType => {
        const company = companies?.find(company => company.id === shiftType.company_id);
        const payStructure = payStructures?.find(payStructure => payStructure.id === shiftType.pay_structure_id);
        return {
          'Name': capitalize(shiftType?.name || ''),
          'Company': company?.name,
          'Pay Structure': capitalize(payStructure?.name?.replace(/_/g, ' ') || '')
        }
      });
      setHeaders(headers);
      setRows(rows);
    }
  }, [shiftTypes, payStructures, companies]);
 return (
  <div className="py-8">
   <Table data={rows} columns={headers}/>
  </div>
 );
};

export default ShiftTypesTable;