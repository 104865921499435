import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  setCompanies, setCredentials,
  setDeliveryData,
  setEndDay, setPayrolls,
  setRevenueData,
  setRevenueDataset, setRoutes, setSickLeaves,
  setStartDay,
  setTimeData, setVacations,
  setVIR,
} from "../../../actions";
import {setMessage as setReduxMessage} from "../../../actions";
import CredentialsForm from "../components/CredentialsForm";
import MessageDisplay from "../components/MessageDisplay";
import ProcessButton from "../components/ProcessButton";
import SubmitCredentialsButton from "../components/SubmitCredentialsButton";
import DateForm from "../components/DateForm";
import webSocketManager from "../../../app/lib/WebSocketManager";
import ProgressBar from "../components/ProgressBar";
import ManagementHeader from "../components/ManagementHeader";
import UploadInput from "../../../components/elements/UploadInput";
import DisplayVIR from "../components/DisplayVIR";
import {parseCsv} from "../../../app/lib";
import UploadTimeData from "../components/UploadTimeData";
import Banner from "../../../components/Banner";
import Query from "./Query";
import SplitOptions from "../components/SplitOptions";
import DisplayUnmatchedTime from "../components/DisplayUnmatchedTime";
import {useNavigate} from "react-router-dom";
import DateInput from "../../../components/DateInput";

const Management = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // state variables
  const [message, setMessage] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [socket, setSocket] = useState(null);
  const [percent, setPercent] = useState(null);
  const [virData, setVirData] = useState(null);
  const [canProcess, setCanProcess] = useState(false);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState(null);
  const [split, setSplit] = useState(null);
  const [unmatched, setUnmatched] = useState(null);

  // redux state
  const VIR = useSelector((state) => state.vir);
  const companies = useSelector((state) => state.companies);
  const units = useSelector((state) => state.units);
  const vehicles = useSelector((state) => state.vehicles);
  const employees = useSelector((state) => state.employees);
  const payStructures = useSelector((state) => state.payStructures);
  const companyRates = useSelector((state) => state.companyRates);
  const shiftTypes = useSelector((state) => state.shiftTypes);
  const account = useSelector((state) => state.account);
  const startDay = useSelector((state) => state.startDay);
  const endDay = useSelector((state) => state.endDay);
  const timeData = useSelector((state) => state.timeData);
  const credentials = useSelector((state) => state.credentials);
  const deliveryData = useSelector((state) => state.deliveryData);
  const revenue = useSelector((state) => state.revenue);
  const revenueDataset = useSelector((state) => state.revenueDataset);
  const payrolls = useSelector((state) => state.payrolls);
  // refs
  const [weekEnd, setWeekEnd] = useState(null);


  useEffect(() => {
    if (!webSocketManager.ws && account)
      webSocketManager.connectSocket(account);
    setSocket(webSocketManager.ws);
  }, [socket, account]);

  useEffect(() => {
    // Reset redux state on component mount
      dispatch(setStartDay(null));
      dispatch(setEndDay(null));
      dispatch(setTimeData(null));
      dispatch(setSickLeaves(null));
      dispatch(setRoutes(null));
      dispatch(setVacations(null));
      dispatch(setDeliveryData(null));
      dispatch(setRevenueData(null));
      dispatch(setRevenueDataset(null));
      dispatch(setCompanies(null));
      dispatch(setCredentials(null));
      dispatch(setPayrolls(null));
      dispatch(setVIR(null));

  }, [dispatch]);

  useEffect(() => {
    const missingData = [];
    if (!units?.length) missingData.push("units");
    if (!vehicles?.length) missingData.push("vehicles");
    if (!employees?.length) missingData.push("employees");
    if (!payStructures?.length) missingData.push("pay structures");
    if (!companyRates?.length) missingData.push("company rates");
    if (!shiftTypes?.length) missingData.push("shift types");
    if (missingData.length) {
      const msg = `Missing data: ${missingData.join(", ")} you must upload the data before starting the process`
      dispatch(setReduxMessage({error: msg}));
      setError(msg)
    } else {
      setCanProcess(true);
    }
  }, [companies, units, vehicles, employees, payStructures, shiftTypes, dispatch, companyRates]);

  useEffect(() => {
    if (!socket) return;
    socket.on("query", (event) => {
      const data = event.detail;
      setQuery(data);
    })
    socket.on("response", (event) => {
      const data = event.detail;
      if (data.message) setMessage(data.message);
      if (data.week_start) dispatch(setStartDay(data.week_start));
      if (data.week_end) dispatch(setEndDay(data.week_end));
      if (data.time_data) dispatch(setTimeData(data.time_data));
      if (data.time_sheets) dispatch(setTimeData(data.time_sheets));
      if (data.sick_leaves) dispatch(setSickLeaves(data.sick_leaves));
      if (data.routes) dispatch(setRoutes(data.routes));
      if (data.percent) setPercent(data.percent);
      if (data.vacations) dispatch(setVacations(data.vacations));
      if (data.delivery_data) dispatch(setDeliveryData(data.delivery_data));
      if (data.revenue) dispatch(setRevenueData(data.revenue));
      if (data.revenue_dataset) dispatch(setRevenueDataset(data.revenue_dataset));
      if (data.companies) dispatch(setCompanies(data.companies));
      if (data.fedex_credentials) dispatch(setCredentials(data.fedex_credentials));
      if (data.payrolls) {
        dispatch(setPayrolls(data.payrolls));
        navigate('/account/payrolls');
      }
      if (data.vir) {
        setVirData(null);
        dispatch(setVIR(data.vir));
      }
      if (data.error) {
        setIsLoading(false);
        dispatch(setReduxMessage({error: data.error}));
      }
      if (data.completed) {
        setIsLoading(false);
        setIsFinished(true);
      }
    });
    socket.on("unmatched_time", (event) => {
      const data = event.detail;
      setUnmatched(data);
    });
    socket.on("split", (event) => {
      const data = event.detail;
      setSplit(data);
    });
    socket.on("disconnect", (event) => {
      const data = event.detail;
      if (data.error) setMessage(data);
      dispatch(setReduxMessage({error: "Disconnected from the server"}));
      setIsLoading(false);
      setTimeout(() => {
        if (account) webSocketManager.connectSocket(account);
      }, 5000);
    });
    return () => {
      if (socket) {
        socket.off("response");
      }
    };
  }, [socket, isLoading, companies, dispatch, account]);

  useEffect(() => {
    if (!companies || !VIR) return;
    const DSPs = companies.filter((c) => c.service_area);
    const cred = DSPs.map((c) => {
      if (c.service_area) return {...c, username: "", password: ""};
      else return undefined;
    });
    setAccounts(cred);
  }, [companies, VIR]);

  // Change the onClick handler to check if inputs are filled before starting the process
  const handleStartProcess = () => {
    if (socket) {
      setIsLoading(true);
      socket.emit("start_process");
    }
  };

  const handleFedexCredentialsSubmit = (e) => {
    if (socket && accounts.every((a) => a.username && a.password)) {
      socket.emit("fedex_credentials", accounts);
    }
  };

  const handleDateSubmit = () => {
    if (socket && weekEnd) {
      socket.emit("date_selected", {date: weekEnd});
    }
  };

  const handleInputChange = (e, index) => {
    const {name, value} = e.target;
    const field = name.split("_")[0];
    const newAccounts = [...accounts];
    newAccounts[index][field] = value;
    setAccounts(newAccounts);
  };

  const handleUploadVehicleInspectionReport = (arrayBuffer) => {
    if (socket) {
      const csv = new TextDecoder("utf-8").decode(arrayBuffer);
      setVirData(parseCsv(csv));
    }
  };

  const handleSendVehicleInspectionReport = (data) => {
    if (socket) socket.emit("upload_vir", {data: data});
  };

  const uploadTimeData = (data) => {
    if (socket) socket.emit("upload_time_data", {data: data});
  };

  const handleQuery = (data) => {
    if (socket) socket.emit("query", {data: data});
    setQuery(null)
  }

  const handleSplit = (data) => {
    if (socket) socket.emit("split", {data: data});
    setSplit(null)
  }

  const handleUnmatched = (data) => {
    if (socket) socket.emit("unmatched_time", data);
    setUnmatched(null)
  }

  const handleDateChange = (date) => {
    setWeekEnd(date)
  };

  return (
    <div className="px-6">
      {error && <Banner className='text-red-700' content={error} onClose={() => setError(null)}/>}
      <ManagementHeader startDate={startDay} endDate={endDay}/>
      {message && <MessageDisplay message={message}/>}
      {percent && <ProgressBar percent={percent}/>}
      {!VIR && startDay && endDay && !virData && (
        <div>
          <p className="text-gray-700 text-lg font-semibold">
            Upload Vehicle Inspection Report
          </p>
          <UploadInput setData={handleUploadVehicleInspectionReport}/>
        </div>
      )}
      {virData && !VIR && (
        <DisplayVIR
          startDay={startDay}
          endDay={endDay}
          setData={handleSendVehicleInspectionReport}
          data={virData}
          onError={setVirData}
        />
      )}
      {(!timeData || timeData.length === 0) && VIR && <UploadTimeData sendData={uploadTimeData}/>}
      {!credentials && timeData &&
        timeData.length > 0 && <div className="my-12">
          {accounts.map((account, index) => (
            <CredentialsForm
              key={index}
              index={index}
              account={account}
              onChange={handleInputChange}
            />
          ))}
        </div>
      }
      {!credentials && timeData && timeData.length > 0 && (
        <SubmitCredentialsButton onClick={handleFedexCredentialsSubmit}/>
      )}
      {canProcess && !startDay && !endDay && (
        <>
          <DateInput value={endDay} onChange={handleDateChange}/>
          <button className="bg-blue-600 text-white px-6 rounded-md py-4 ml-6"
            onClick={handleDateSubmit}>Submit</button>
        </>
      )}
      {!split && credentials && (
        <div className="my-12">
          <ProcessButton
            isLoading={isLoading}
            isFinished={isFinished}
            handleStartProcess={handleStartProcess}
          />
        </div>
      )}
      {query && <Query data={query} onSubmit={handleQuery}/>}
      {split && <SplitOptions options={split} onSubmit={handleSplit}/>}
      {unmatched && <DisplayUnmatchedTime data={unmatched} onSubmit={handleUnmatched}/>}
    </div>
  );
};

export default Management;
