import React from 'react';
import Select from "../../../../components/elements/Select";

const QuerySelect = ({options, handleSelectChange}) => {
  return (
    <div className="mt-2">
      <Select selected={options[0]} setSelected={handleSelectChange} options={options}
              name={options.name}/>
    </div>
  );
};

export default QuerySelect;