import React from 'react';

const ManagementHeader = ({startDate, endDate}) => {
  return (
    <h2 className="text-xl font-bold text-center text-gray-600 py-3">
      Data Management Dashboard
      {startDate && endDate && <div className="text-sm text-gray-500">
        working on period: {startDate} - {endDate}
      </div>}
    </h2>
  );
};

export default ManagementHeader;