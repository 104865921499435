import {SET_MESSAGE, SET_ERROR, RESET_STATE} from '../actions/types';

const message = (state = null, action) => {
    switch (action.type) {
        case RESET_STATE:
            return null;
        case SET_MESSAGE:
            return action.payload;
        case SET_ERROR:
            return action.payload;
        default:
            return state;
    }
};

export default message;