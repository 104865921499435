import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import LoginForm from "./LoginForm";
import {useEffect} from 'react';
import Container from "../../components/elements/Container";
import Header from "../header/Header";
import Footer from "../footer/Footer";

function Login() {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const config = useSelector(state => state.config);

  useEffect(() => {
    if (user) navigate(config.routes.profile, {replace: true});
  }, [user, navigate, config]);

  return (
    <Fragment>
      <Header/>
      <section className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <Container>
          <div className="h-screen">
            <LoginForm/>
          </div>
        </Container>
      </section>
      <Footer/>
    </Fragment>
  )
}

export default Login;