import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";


const CookieNotice = () => {
  const [isCookieNoticeVisible, setCookieNoticeVisible] = useState(localStorage.getItem('cookieNotice') !== 'true');
  const {routes} = useSelector(state => state.config);
  const handleAcceptCookies = () => {
    localStorage.setItem('cookieNotice', 'true');
    setCookieNoticeVisible(false);
  }

  return (
    <>
      {isCookieNoticeVisible && (
        <div className="pointer-events-none fixed inset-x-0 bottom-2 px-6 pb-6" style={{zIndex: '999'}}>
          <div className="pointer-events-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
            <p className="text-sm leading-6 text-gray-900">
              This website uses cookies to supplement a balanced diet and provide a much deserved reward to the senses
              after
              consuming bland but nutritious meals. Accepting our cookies is optional but recommended, as they are
              delicious. See our{' '}
              <Link to={routes.cookiePolicy} className="font-semibold text-blue-500 hover:text-blue-400">cookie policy</Link>
              .
            </p>
            <div className="mt-4 flex items-center gap-x-5">
              <button
                type="button" onClick={handleAcceptCookies}
                className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              >
                Accept all
              </button>
              <button type="button" onClick={handleAcceptCookies}
                      className="text-sm font-semibold leading-6 text-gray-900">
                Accept necessary
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieNotice;