import React from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { parse } from 'date-fns';

const TimeInput = ({ value, onChange, index }) => {
  // Parse the incoming value as a Date object
  const timeValue = value ? parse(value, "hh:mm a", new Date()) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        value={timeValue}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            name={`time-${index}`}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default TimeInput;
