import React, {Fragment} from 'react';
import {Menu, Transition} from "@headlessui/react";
import {Link} from "react-router-dom";
import {HiArrowRightOnRectangle, HiOutlineUser} from "react-icons/hi2";
import {useSelector} from "react-redux";


const ProfileHeaderMenu = () => {
  const {routes} = useSelector(state => state.config);

  const userNavigation = [
    {name: 'Your profile', href: routes.profile, icon: HiOutlineUser},
    {name: 'Sign out', href: routes.logout, icon: HiArrowRightOnRectangle},
  ];

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className="absolute right-0 z-10 mt-2.5 w-36 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
        {userNavigation.map((item) => (
          <Menu.Item key={item.name}>
            {({active}) => (
              <Link
                to={item.href}
                className='px-3 py-1 text-sm leading-6 text-gray-900 flex justify-start items-center'>
                <item.icon className="mr-2"/>
                {item.name}
              </Link>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Transition>
  );
};

export default ProfileHeaderMenu;