import React, {useState} from 'react';
import {BsFillCloudPlusFill} from "react-icons/bs";
import ImageEditorModal from "./ImageEditorModal";

const UserImage = ({image}) => {
  const [crop, setCrop] = useState({aspect: 1, shape: 'round'});
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setCroppedImageUrl(reader.result);
      });
      reader.readAsDataURL(file);
    }
    e.target.value = null;
  }

  return (
    <div className="py-4 flex justify-items-center items-center sm:py-5 sm:pt-5">
      {croppedImageUrl && (
        <ImageEditorModal
          image={croppedImageUrl}
          setImage={setCroppedImageUrl}
          crop={crop}
          setCrop={setCrop}
        />
      )}
      <div className="w-full">
        <img
          className="h-16 w-16 rounded-full"
          src={image}
          alt=""
        />
      </div>
      <div className="w-full h-16">
        <div className="flex items-center justify-center w-full mx-6 h-full">
          <label htmlFor="dropzone-file"
                 className="flex flex-col items-center justify-center w-full border border-gray-100 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 h-full text-gray-500 hover:text-blue-500">
            <div className="flex flex-col items-center justify-center px-1">
              <BsFillCloudPlusFill className="w-6 h-6"/>
            </div>
            <input id="dropzone-file" type="file" className="hidden" onChange={handleImageChange}/>
          </label>
        </div>
      </div>
    </div>
  );
};

export default UserImage;