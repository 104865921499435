import React, {useState} from 'react';
import {BsPencilSquare, BsTrash} from "react-icons/bs";
import {FaSortUp, FaSortDown, FaSort} from "react-icons/fa";
import SearchBar from "../SearchBar";
import PhoneNumber from "../PhoneNumber";
import Email from "../Email";

const Table = React.memo(({columns, data, handleDelete, handleEdit}) => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const iconClasses = 'inline text-gray-400 hover:text-gray-500 ml-2';
  const [searchQuery, setSearchQuery] = useState('');


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (sortColumn && sortDirection) {
      const aValue = a[sortColumn];
      const bValue = b[sortColumn];

      // If both values are numbers, compare them numerically
      if (!isNaN(aValue) && !isNaN(bValue)) {
        return sortDirection === 'asc'
          ? aValue - bValue
          : bValue - aValue;
      }

      // If not, compare them as strings
      return sortDirection === 'asc'
        ? String(aValue).localeCompare(String(bValue))
        : String(bValue).localeCompare(String(aValue));
    }
    return 0;
  });

  // Filter data based on search query
  const filteredData = sortedData.filter(row => {
    return columns.some(column => {
      return String(row[column])
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    });
  });

  const renderCellContent = (columnIndex, columnValue) => {
    switch (columnIndex) {
      case 2:
        return <Email email={columnValue} displayedEmail={columnValue}/>;
      case 3:
        return <PhoneNumber phoneNumber={columnValue}/>;
      default:
        return columnValue;
    }
  };

  return (
    <div>
      <SearchBar placeholder="Search..."
                 data={data}
                 setSearchQuery={setSearchQuery}
                 handleSearchChange={handleSearchChange}
                 searchQuery={searchQuery}/>
      <table className="table-auto min-w-full divide-y divide-gray-300 animate-[fade-in-down_1s_ease-in-out]">
        <thead className="bg-gray-50">
        <tr>
          {columns.map((column, index) => (
            <th key={index} scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer "
                onClick={() => handleSort(column)}>
              <div className="flex items-center">
                {column}
                {sortColumn === column && (sortDirection === 'desc' ?
                  <FaSortUp className={`${iconClasses} fill-blue-500`}/> :
                  <FaSortDown className={`${iconClasses} fill-blue-500`}/>)}
                {sortColumn !== column && <FaSort className={iconClasses}/>}
              </div>

            </th>
          ))}
          {handleDelete && <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>}
        </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {filteredData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {/* Render table cells */}
              {columns.map((column, columnIndex) => (
                <td key={columnIndex} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {renderCellContent(columnIndex, row[column])}
                </td>
              ))}
              {/* Edit Button */}
              {handleEdit && (
                <td className="px-3 py-4 text-sm">
                  <button onClick={() => handleEdit(row)} className="text-blue-300 hover:text-blue-500 hover:scale-125">
                    <BsPencilSquare/>
                  </button>
                </td>
              )}
              {/* Delete Button */}
              {handleDelete && (
                <td className="px-3 py-4 text-sm">
                  <button onClick={() => handleDelete(row)} className="text-red-300 hover:text-red-500 hover:scale-125">
                    <BsTrash/>
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

Table.displayName = 'Table';

export default Table;
