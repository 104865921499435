import {formatPhoneNumber} from "../app/lib";
import React from "react";
import {Link} from "react-router-dom";

const PhoneNumber = ({phoneNumber}) => (
  <Link to={`tel:${phoneNumber}`} className="text-decoration-none hover:text-blue-500">
    {formatPhoneNumber(phoneNumber)}
  </Link>
);

export default PhoneNumber;