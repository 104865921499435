import {RESET_STATE, SET_ROUTES} from '../actions/types';

const routes = (state = null, action) => {
  switch (action.type) {
    case RESET_STATE:
      return null;
    case SET_ROUTES:
      return action.payload;
    default:
      return state;
  }
};

export default routes;