export const RESET_STATE = 'RESET_STATE';
export const SET_USER = 'SET_USER';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_MANAGER_MENU = 'SET_MANAGER_MENU';
export const SET_SERVER_STATUS = 'SET_SERVER_STATUS';
export const SET_FEDEX_ID = 'SET_FEDEX_ID';
export const SET_TIME_DATA = 'SET_TIME_DATA';
export const SET_OUTPUT_DATA = 'SET_OUTPUT_DATA';
export const SET_REVENUE_DATA = 'SET_REVENUE_DATA';
export const SET_REVENUE_DATASET = 'SET_REVENUE_DATASET';
export const SET_REVENUES = 'SET_REVENUES';
export const SET_USERS = 'SET_USERS';
export const SET_PAYROLLS = 'SET_PAYROLLS';
export const SET_PAYROLLS_PLUS = 'SET_PAYROLLS_PLUS';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_SUB_COMPANIES = 'SET_SUB_COMPANIES';
export const SET_SETTINGS_MENU = 'SET_SETTINGS_MENU';
export const SET_CHARTS = 'SET_CHARTS';
export const SET_VIR = 'SET_VIR';
export const SET_VEHICLES = 'SET_VEHICLES';
export const SET_PASSED_COMPANY_IDS = 'SET_PASSED_COMPANY_IDS';
export const SET_CHOSEN_DATE = 'SET_CHOSEN_DATE';
export const SET_DELIVERY_DATA = 'SET_DELIVERY_DATA';
export const SET_SECRETS = 'SET_SECRETS';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_START_DAY = 'SET_START_DAY';
export const SET_END_DAY = 'SET_END_DAY';
export const SET_COMPANY_RATES = 'SET_COMPANY_RATES';
export const SET_UNITS = 'SET_UNITS';
export const SET_SHIFT_TYPES = 'SET_SHIFT_TYPES';
export const SET_PAY_STRUCTURES = 'SET_PAY_STRUCTURES';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_VACATIONS = 'SET_VACATIONS';
export const SET_SICK_LEAVES = 'SET_SICK_LEAVES';
export const SET_ROUTES = 'SET_ROUTES';
export const SET_PAY_TYPES = 'SET_PAY_TYPES';
