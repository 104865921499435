import React from "react";
import Chart from "./Chart";
import {useSelector} from "react-redux";

const MyIncome = ({data}) => {
  const myIncome = useSelector(store => store.charts.myIncome);


  const incomeData = data || myIncome;
  const msg = myIncome === incomeData ? 'You have no data' : 'Monthly income'

  return (
    <div className="h-[22rem]">
      <h1 className="text-xl text-gray-600 pb-8">My income</h1>
      {myIncome === incomeData && <p className="text-gray-500">{msg}</p>}
      <Chart type="line" data={incomeData} label={msg}/>
    </div>
  );
};

export default MyIncome;
