import React from 'react';
import {HiChevronLeft, HiOutlineCog8Tooth} from "react-icons/hi2";
import {Link} from "react-router-dom";
import Logo from "../../../components/icons/Logo";
import NavItem from "./NavItem";
import {useSelector} from "react-redux";
import LinkItem from "./LinkItem";


const ProfileSidebar = ({navigation, links, isFolded, setFolded}) => {
  const config = useSelector(store => store.config);
  return (
    <div className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-${isFolded ? '20' : '72'} lg:flex-col`}>
      <div className="h-screen flex justify-items-start items-start">
        <div className={`flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4 ${isFolded ? 'w-20' : 'w-64'}`}>
          <Link to={config.routes.home} className="flex h-16 shrink-0 items-center">
            <Logo className="h-12 w-12"/>
          </Link>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1 pl-2">
                  {navigation.map(item => item && <NavItem key={`${item.id}${item.name}`} item={item} Icon={item.icon}
                                                   isFolded={isFolded}/>)}
                </ul>
              </li>
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">Data</div>
                <ul className="-mx-2 mt-2 space-y-1 pl-2">
                  {links.map(item => item?.display && <LinkItem key={`${item.id}${item.name}`} item={item} isFolded={isFolded}/>)}
                </ul>
              </li>
              <li className="mt-auto">
                <Link
                  to={`${config.routes.profile}${config.routes.settings}`}
                  className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-blue-500"
                >
                  <HiOutlineCog8Tooth
                    className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-blue-500 pl-2"
                    aria-hidden="true"
                  />
                  {!isFolded && <div>Settings</div>}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="p-4">
          <button onClick={() => setFolded(!isFolded)}
                  className={'p-1.5 border rounded-full bg-gray-50 hover:bg-gradient-to-r hover:from-cyan-500 hover:to-blue-500'}>
            <HiChevronLeft
              className={`${isFolded ? 'rotate-180' : ''} fill-gray-700 hover:fill-white w-5 h-5`}/>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSidebar;