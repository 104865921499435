import {getBaseUrl} from "./index";

class WebSocketManager {
  constructor(path) {
    this.path = path;
    this.ws = null;
    this.eventTarget = new EventTarget();
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 10;
    this.account = null;
  }

  connectSocket(account) {
    this.account = account;
    if (!this.account) return;
    if (!this.ws) this.ws = new WebSocket(this.path);

    this.ws.onopen = () => {
      console.info('Connected to the server');
      this.reconnectAttempts = 0;  // Reset the number of reconnect attempts
    };

    this.ws.onclose = () => {
      console.info('Disconnected from the server');
      this.ws = null;
      const customEvent = new CustomEvent('disconnect', {detail: {message: 'Disconnected from the server'}});
      this.eventTarget.dispatchEvent(customEvent);

      // Try to reconnect after a delay, unless we've already reached the maximum number of attempts
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        setTimeout(() => this.connectSocket(), 1000 * (this.reconnectAttempts + 1));  // Delay increases with each attempt
        this.reconnectAttempts++;
      }
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    this.ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.event) {
        const customEvent = new CustomEvent(data.event, {detail: data.data});
        this.eventTarget.dispatchEvent(customEvent);
      }
    };

    this.ws.on = (eventName, listener) => {
      this.eventTarget.addEventListener(eventName, listener);
    };

    this.ws.off = (eventName, listener) => {
      this.eventTarget.removeEventListener(eventName, listener);
    };

    this.ws.emit = (eventName, data) => {
       this.ws && this.ws.send(JSON.stringify({event: eventName, data}));
    };
  }

  disconnectSocket() {
    if (this.ws) {
      this.ws.close();
    }
  }
}

const baseUrl = getBaseUrl().split('//')[1];
const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
const webSocketManager = new WebSocketManager(`${wsProtocol}//${baseUrl}/ws/`);

export default webSocketManager;