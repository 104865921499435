import {setMessage,  setUser} from "../../actions";
import api from "../request";

export const loginUser = (credentials, dispatch, url) => {
  return api.post(url, credentials)
    .then(response => {
      if (response?.data?.user) dispatch(setUser(response.data.user));
    })
    .catch(res => dispatch(setMessage(res.response.data)));
}


