import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";


const LearnMoreHero = () => {
  const {routes} = useSelector(state => state.config);
 return (
  <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
        <div
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
          aria-hidden="true"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
          <div
            className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h1
              className="max-w-2xl text-xl font-bold tracking-tight text-gray-800 sm:text-4xl lg:col-span-2 xl:col-auto pb-16">
              Transforming Payroll Management for the Modern World
            </h1>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg leading-8 text-gray-500">
                In today's fast-paced, interconnected world, managing payroll should be as simple as clicking a button.
                That's why we've created our innovative payroll app, designed to streamline payroll processes and make
                them as efficient and user-friendly as possible.
              </p>
              <p className="text-lg leading-8 text-gray-500 pt-8">
                With our unique and innovative features, we're changing the way people connect with payroll. We're
                making it more intuitive, more accessible, and more in tune with the way we work today. Join us on this
                journey and experience the future of payroll management.
              </p>
              <div className="mt-10 flex items-center gap-x-6">
                <Link
                  to={routes.login}
                  className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started
                </Link>
              </div>
            </div>

            <img
              src="https://images.pexels.com/photos/3760069/pexels-photo-3760069.jpeg?auto=compress&amp;cs=tinysrgb&amp;w=1260&amp;h=750&amp;dpr=1"
              alt=""
              className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32"/>
      </div>
 );
};

export default LearnMoreHero;