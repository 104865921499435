import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setCompanies, setMessage} from "../../../actions";
import Input from "../../../components/elements/Input";
import Button from "../../../components/elements/Button";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {createCompany} from "../../hooks/queries";


const CompanyForm = () => {
  const dispatch = useDispatch();
  const companies = useSelector(store => store.companies);
  const [companyName, setCompanyName] = useState('');
  const [serviceArea, setServiceArea] = useState('');
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: createCompany,
    onSuccess: (data) => {
      if (data?.response?.status === 400 && data?.response?.data?.error) {
        return dispatch(setMessage(data?.response?.data));
      }
      if (data?.data?.id) {
        const currentCompanies = companies ? companies : [];
        const newCompany = {...data.data};
        const newCompanies = [...currentCompanies, newCompany];
        setCompanyName('');
        setServiceArea('');
        return dispatch(setCompanies(newCompanies));
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSubmit = () => {
    mutation.mutate({name: companyName, service_area: serviceArea.length > 0 ? serviceArea : null});
    // Invalidate the 'companies' query to refetch it
    queryClient.invalidateQueries('companies');
  };

  return (
    <form className="w-full py-10">
      <div className="grid md:grid-cols-12 md:gap-6">
        <div className="md:col-span-5 relative z-0 w-full mb-6 group ">
          <Input type="text" name="companyName" value={companyName} onChange={e => setCompanyName(e.target.value)}
                 label={'Company name'}/>
        </div>
        <div className="md:col-span-5 relative z-0 w-full mb-6 group">
          <Input type="text" name="serviceAria" value={serviceArea} onChange={e => setServiceArea(e.target.value)}
                 label={'Service area (optional)'}/>
        </div>
        <div className="md:col-span-2 relative z-0 w-full mt-5 group">
          <Button children={"Submit"} onClick={handleSubmit}/>
        </div>
      </div>
    </form>
  );

};

export default CompanyForm;