import React from "react";
import IconWrapper from "./IconWrapper";

const FacebookIcon = ({viewBox, className = "w-3 h-3 text-white"}) => {
  return (
    <IconWrapper className={className} viewBox={viewBox}>
      <path
        d="M220 120a100 100 0 10-116 99v-70H80V120h25V98c0-25 15-39 38-39l23 2v25h-13c-12 0-16 7-16 15V120h27l-4 29h-23v70A100 100 0 00 220 120"
        clipRule="evenodd" fillRule="evenodd"/>
    </IconWrapper>
  )
}

export default FacebookIcon;