import React from "react";
import IconWrapper from "./IconWrapper";

const GitHubIcon = ({viewBox, className = "w-3 h-3 text-white"}) => {
  return (
    <IconWrapper className={className} viewBox={viewBox}>
      <path d="M120 20a100 100 0 00-32 195c5 1 7-2 7-5v-17C67 200 61 180 61 180c-4-12-10-15-10-15-10-6 0-6 0-6 10 0 15 10 15 10 10 16 24 11 30 9 0-7 3-11 6-14-22-2-46-10-46-50a40 40 0 01 10-26c0-2-4-13 1-26 0 0 9-3 28 10a96 96 0 01 25-4c8 0 17 2 25 4 20-13 28-10 28-10 5 13 2 24 0 26 7 7 10 16 10 27 0 38-23 47-45 50 4 2 7 9 7 18V210c0 3 2 6 7 5A100 100 0 00 220 120 100 100 0 00 120 20" clipRule="evenodd" fillRule="evenodd"/>
    </IconWrapper>
  )
}

export default GitHubIcon;