import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import UploadInput from "../../../components/elements/UploadInput";
import {setVehicles} from "../../../actions";
import Table from "../../../components/elements/Table";
import ParagraphMissingData from "../../../components/elements/ParagraphMissingData";
import ParagraphAmount from "../../../components/elements/ParagraphAmount";
import PageHeader from "../../../components/elements/PageHeader";
import {useQuery} from "@tanstack/react-query";
import {fetchVehicles} from "../../hooks/queries";

const VehiclePage = () => {
  const dispatch = useDispatch();
  const companies = useSelector(store => store.companies);
  const vehicles = useSelector(store => store.vehicles);
  const urls = useSelector(store => store.config.urls);
  const vehicleHeader = ['VEHICLE NUMBER', 'COMPANY NAME', 'VEHICLE MAKE'];
  const [data, setData] = useState([]);

  const vehiclesData = useQuery({
    queryKey: ["vehicles"],
    queryFn: fetchVehicles,
    enabled: !vehicles || vehicles.length === 0
  });

  useEffect(() => {
    if (vehiclesData?.data || vehiclesData?.data?.length === 0)
      dispatch(setVehicles(vehiclesData.data));
  }, [dispatch, urls.vehicles, vehicles]);

  useEffect(() => {
    if (vehicles && vehicles.length > 0) {
      const data = vehicles.map(vehicle => {
        const company = companies.find(company => company.id === vehicle.company_id);
        return {
          'VEHICLE NUMBER': vehicle.vehicle_number,
          'COMPANY NAME': company?.name,
          'VEHICLE MAKE': vehicle.make
        }
      });
      setData(data);
    }
  }, [companies, vehicles, urls.vehicles]);


  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <PageHeader header={"Vehicles"}/>
      {(!vehicles || vehicles.length === 0) && <ParagraphMissingData name={"vehicles"}/>}
      {vehicles && vehicles.length > 0 && <ParagraphAmount name={"vehicles"} amount={vehicles.length}/>}

      <UploadInput setData={setVehicles} url={`${urls.vehicles}${urls.upload}`}/>
      {vehicles && vehicles.length > 0 &&
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <Table columns={vehicleHeader} data={data}/>
        </div>}
    </div>
  );
};

export default VehiclePage;