import React from "react";

const LineButton = ({label, additionalClasses, onClick}) => (
  <button
    type="button"
    onClick={onClick}
    className={`relative items-center px-3 py-2 text-sm font-semibold text-gray-600 border-gray-50 border-b hover:text-black ${additionalClasses}`}
  >{label}</button>
);

export default LineButton;