import React from 'react';
import {HiOutlineOfficeBuilding, HiOutlineCalendar} from "react-icons/hi";
import {FiClock} from "react-icons/fi";
import {Link} from "react-router-dom";

const PayrollCard = ({payroll}) => {
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  const newPayroll = new Date(payroll.date) > sevenDaysAgo;
  return (
    <div className="w-full">
      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5">
        <dl className="flex flex-wrap">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm font-semibold leading-6 text-gray-900">Amount</dt>
            <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">${payroll.total}</dd>
          </div>
          <div className="flex-none self-end px-6 pt-4">
            <dt className="sr-only">Status</dt>
            {newPayroll && <dd
              className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
              new
            </dd>}
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
            <dt className="flex-none">
              <span className="sr-only">Company</span>
              <HiOutlineOfficeBuilding className="h-6 w-5 text-gray-400" aria-hidden="true"/>
            </dt>
            <dd className="text-sm font-medium leading-6 text-gray-900">{payroll?.company}</dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <HiOutlineCalendar className="h-6 w-5 text-gray-400" aria-hidden="true"/>
            </dt>
            <dd className="text-sm leading-6 text-gray-500">
              <time dateTime="2023-01-31">{payroll.date}</time>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
            <dt className="flex-none">
              <span className="sr-only">Hours</span>
              <FiClock className="h-6 w-5 text-gray-400" aria-hidden="true"/>
            </dt>
            <dd className="text-sm leading-6 text-gray-500">Worked hours: {payroll.worked_hours}</dd>
          </div>
        </dl>
        <div className="mt-6 border-t border-gray-900/5 px-6 py-6">
          <Link to="#" className="text-sm font-semibold leading-6 text-gray-900">
            Open <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PayrollCard;