import React from 'react';
import {HiOutlineMail, HiOutlineOfficeBuilding, HiOutlinePhone} from "react-icons/hi";
import {Link} from "react-router-dom";


const ContactInfo = () => {
  const handleOpenMap = () => {
    window.open(
      'https://www.google.com/maps/place/3702+W+Valley+Hwy+N+Auburn%2C+WA+98001',
      '_blank',
      'noopener,noreferrer,width=800,height=600,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=0,top=0')
  }
 return (
   <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
     <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
       <div
         className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
         <svg
           className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
           aria-hidden="true"
         >
           <defs>
             <pattern
               id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
               width={200}
               height={200}
               x="100%"
               y={-1}
               patternUnits="userSpaceOnUse"
             >
               <path d="M130 200V.5M.5 .5H200" fill="none"/>
             </pattern>
           </defs>
           <rect width="100%" height="100%" strokeWidth={0} fill="white"/>
           <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
             <path d="M-470.5 0h201v201h-201Z" strokeWidth={0}/>
           </svg>
           <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"/>
         </svg>
       </div>
       <h2 className="text-3xl font-bold tracking-tight text-gray-700">
         Contact Us
       </h2>
       <p className="mt-6 text-lg leading-8 text-gray-600">
         We are here to help you with any questions you may have.
         Reach out to us and we'll respond as soon as we can.
       </p>
       <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
         <div className="flex gap-x-4">
           <dt className="flex-none">
             <span className="sr-only">Address</span>
             <HiOutlineOfficeBuilding className="h-7 w-6 text-gray-400" aria-hidden="true"/>
           </dt>
           <dd onClick={handleOpenMap} className="cursor-pointer text-gray-500 hover:text-blue-500">
             3702 W Valley Hwy N Auburn, WA 98001
           </dd>
         </div>
         <div className="flex gap-x-4">
           <dt className="flex-none">
             <span className="sr-only">Telephone</span>
             <HiOutlinePhone className="h-7 w-6 text-gray-400" aria-hidden="true"/>
           </dt>
           <dd>
             <Link className="text-gray-500 hover:text-blue-500" to="tel:+1 (206) 406-6863">
               +1 (206) 406-6863
             </Link>
           </dd>
         </div>
         <div className="flex gap-x-4">
           <dt className="flex-none">
             <span className="sr-only">Email</span>
             <HiOutlineMail className="h-7 w-6 text-gray-400" aria-hidden="true"/>
           </dt>
           <dd>
             <Link className="text-gray-500 hover:text-blue-500" to="mailto:hello@example.com">
               fuji@seatttleground.com
             </Link>
           </dd>
         </div>
       </dl>
     </div>
   </div>
 );
};

export default ContactInfo;