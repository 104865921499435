import React from 'react';
import {
  FaGoogle,
  FaAws,
  FaReact,
  FaFedex,
  FaBitbucket,
  FaMicrosoft,
  FaNpm,
  FaPython
} from "react-icons/fa";
import { SiFlask , SiMysql } from "react-icons/si";

const LearnMoreLogos = () => {
  const logos = [
    {
      id: 1,
      name: "Bitbucket",
      icon: <FaBitbucket className="text-3xl text-blue-500"/>
    },
    {
      id: 2,
      name: "Google",
      icon: <FaGoogle className="text-3xl text-blue-500"/>
    },
    {
      id: 3,
      name: "AWS",
      icon: <FaAws className="text-4xl text-blue-500"/>
    },
    {
      id: 4,
      name: "Fedex",
      icon: <FaFedex className="text-5xl text-blue-500"/>
    },
    {
      id: 5,
      name: "React",
      icon: <FaReact className="text-3xl text-blue-500"/>
    },
    {
      id: 6,
      name: "Microsoft",
      icon: <FaMicrosoft className="text-3xl text-blue-500"/>
    },
    {
      id: 7,
      name: "NPM",
      icon: <FaNpm className="text-4xl text-blue-500"/>
    },
    {
      id: 8,
      name: "Python",
      icon: <FaPython className="text-4xl text-blue-500"/>
    },
    {
      id: 9,
      name: "Flask",
      icon: <SiFlask className="text-4xl text-blue-500"/>
    },
    {
      id: 10,
      name: "MySQL",
      icon: <SiMysql className="text-4xl text-blue-500"/>
    }
  ];


  return (
    <div className="bg-white py-24 sm:py-32 mt-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-700 sm:text-4xl pb-28 text-center">
          Technology that powers our platform
        </h2>
        <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          {logos.map((logo) => (
            <div key={logo.id} className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              {logo.icon}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LearnMoreLogos;