import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import DateForm from "../components/DateForm";
import {useNavigate} from "react-router-dom";
import api from "../../../app/request";
import {setEndDay, setMessage, setPayrolls} from "../../../actions";
import DateInput from "../../../components/DateInput";

const PayrollsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companies = useSelector(store => store.companies);
  const payrolls = useSelector(store => store.payrolls);
  const employees = useSelector(store => store.employees);
  const startDay = useSelector(store => store.startDay);
  const endDay = useSelector(store => store.endDay);

  const [loading, setLoading] = useState(false);
  const [weekStart, setWeekStart] = useState(startDay || '');
  const [weekEnd, setWeekEnd] = useState(endDay || '');

  const inputRef = React.useRef(null);
  const payrollsByCompany = payrolls && companies?.map(company => ({
    ...company,
    payrolls: payrolls.filter(payroll => payroll.company_id === company.id),
  }));
  // Tab state
  const [activeTab, setActiveTab] = useState(payrollsByCompany?.length > 0 ? payrollsByCompany[0]?.id : null);


  useEffect(() => {
    if (inputRef.current?.value === '') {
      inputRef.current.value = weekEnd;
      inputRef.current.focus();
    }
  }, [inputRef.current?.value, weekEnd]);

  // Functions to handle button actions (placeholders for now)
  const handleApprove = (payrollId) => {
    // Implementation for handleApprove
  };

  const handlePublish = (payrollId) => {
    // Implementation for handlePublish
  };

  const handlePublishAll = (companyId) => {
    // Implementation for handlePublishAll
  };

  const handleEditPayroll = (payrollId) => {
    navigate(`/account/payrolls/${payrollId}`)
  };

  const handleAddPayroll = (companyId) => {
    // Implementation for handleAddPayroll
  };

  const handleDateSubmit = () => {

    const start = new Date(weekEnd);
    start.setDate(start.getDate() - 6);
    // format the start date to mm/dd/yyyy
    const month = start.getMonth() + 1;
    const day = start.getDate();
    const year = start.getFullYear();
    const formattedStart = `${month}/${day}/${year}`;
    const formattedWeekEnd = weekEnd ? new Date(weekEnd).toLocaleDateString() : '';
    setWeekEnd(formattedWeekEnd);
    setWeekStart(formattedStart);
    api.get(`/payrolls/?week_end_date=${weekEnd.replace(/\//g, '-')}`).then(res => {
      dispatch(setPayrolls(res.data));
      setActiveTab(companies[0].id);
    }).catch(err => {
      dispatch(setMessage({error: err.toString()}))
    })

  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold text-gray-900">Payrolls</h1>
      <p className="mt-1 text-sm text-gray-500">Payroll period from {weekStart} to {weekEnd}</p>
      <DateInput
        value={weekEnd}
        onChange={setWeekEnd}
      />
      <button
        className="ml-4 px-4 py-4 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={handleDateSubmit}
      >
    Submit
  </button>
        <div className="mt-6">
          <div className="flex space-x-4 border-b">
            {payrollsByCompany && payrollsByCompany.map(company => (
              <button
                key={company.id}
                className={`px-3 py-2 text-sm font-medium ${activeTab === company.id ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-600'}`}
                onClick={() => setActiveTab(company.id)}
              >
                {company.name}
              </button>
            ))}
          </div>

          <div className="mt-4">
            {payrollsByCompany && payrollsByCompany.filter(company => company.id === activeTab).map(company => (
              <div key={company.id}>
                <div className="mb-6">
                  <button
                    className="my-4 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                    onClick={() => handleAddPayroll(company.id)}
                  >
                    Add Payroll
                  </button>

                  {company.payrolls?.length > 0 && <button
                    className="my-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 float-right"
                    onClick={() => handlePublishAll(company.id)}
                  >
                    Publish All
                  </button>}
                </div>


                {company.payrolls.map(payroll => {
                  const employee = employees.find(e => e.id === payroll.employee_id);
                  // Calculating the total for this payroll
                  const payrollTotal = payroll.pay_rows.reduce((acc, item) => acc + item.total, 0);

                  return (
                    <div key={payroll.id} className="p-4 border rounded-lg mb-4 shadow relative">
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="text-lg font-semibold">{employee.first_name} {employee.last_name}</p>

                        </div>
                        <div>
                          <p
                            className={`text-sm font-semibold ${payroll.is_approved ? 'text-green-500' : 'text-red-500'}`}>{payroll.is_approved ? 'Approved' : 'Not Approved'}</p>
                          <p
                            className={`text-sm font-semibold ${payroll.is_published ? 'text-green-500' : 'text-red-500'}`}>{payroll.is_published ? 'Published' : 'Not Published'}</p>
                        </div>

                      </div>
                      <div className="flex space-x-2 mt-2">
                        <button
                          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                          onClick={() => handleApprove(payroll.id)}
                        >
                          Approve
                        </button>
                        <button
                          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                          onClick={() => handlePublish(payroll.id)}
                        >
                          Publish
                        </button>
                        <button
                          className="px-4 py-2 bg-yellow-600 text-white rounded hover:bg-yellow-700"
                          onClick={() => handleEditPayroll(payroll.id)}
                        >
                          Edit Payroll
                        </button>

                      </div>
                      <span className="text-gray-600 float-right absolute bottom-2 right-4">
                      <span className="font-semibold">Total: </span>
                      <span className="font-bold">${payrollTotal.toFixed(2)}</span>
                    </span>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
    </div>
);
};

export default PayrollsPage;