import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import CompanyForm from "../components/CompanyForm";
import Table from "../../../components/elements/Table";
import ParagraphMissingData from "../../../components/elements/ParagraphMissingData";
import ParagraphAmount from "../../../components/elements/ParagraphAmount";
import PageHeader from "../../../components/elements/PageHeader";
import {removeCompany} from "../../hooks/queries";
import {setCompanies, setMessage} from "../../../actions";

const CompaniesPage = () => {
  const dispatch = useDispatch();
  const companies = useSelector(store => store.companies);
  const header = ['COMPANY ID', 'COMPANY NAME', 'SERVICE AREA'];
  const [data, setData] = useState([]);

  useEffect(() => {
    if (companies && companies.length > 0) {
      const data = companies.map(company => {
        return {
          'COMPANY ID': company.id,
          'COMPANY NAME': company.name,
          'SERVICE AREA': company.service_area
        }
      });
      setData(data);
    }
  }, [companies]);

  const handleDelete = async row => {

    const res = await removeCompany(row['COMPANY ID']);

    if (res?.status === 200 && res?.data?.message) {
      const payload = companies.filter(c => c.id !== row['COMPANY ID']);
      dispatch(setCompanies(payload));
      dispatch(setMessage(res.data));
    }
  }

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <PageHeader header={"Companies"}/>
      {(!companies || companies.length === 0) && <ParagraphMissingData name={"companies"}/>}
      {companies && companies.length > 0 && <ParagraphAmount name={"companies"} amount={companies.length}/>}
      <CompanyForm/>
      {companies && companies.length > 0 && <div
        className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <Table columns={header} data={data} handleDelete={handleDelete}/>
      </div>}
    </div>
  );
};

export default CompaniesPage;