import React from 'react';
import {useSelector} from "react-redux";
import Button from "../../../components/elements/Button";


const SplitOptions = ({options, onSubmit}) => {
  const [updatedOptions, setUpdatedOptions] = React.useState([...options]);
  const routes = useSelector((state) => state.routes);
  const companies = useSelector((state) => state.companies);
  const employees = useSelector((state) => state.employees);

  const handleSelect = (option, employee_id) => {
    const newOption = option.map(item => {
      if (item.employee_id === employee_id) {
        return {...item, split: !item.split};
      }
      return item;
    });

    const optionIndex = updatedOptions.findIndex(opt => opt.some(item => item.employee_id === employee_id));
    const newUpdatedOptions = [...updatedOptions];
    newUpdatedOptions[optionIndex] = newOption;

    setUpdatedOptions(newUpdatedOptions);
  };

  return (
    <div className="my-12">
      <p className="text-gray-700 font-semibold text-3xl text-center">
        Split Options
      </p>
      <div className="bg-gray-50 block py-2">
        {updatedOptions.map((option, index) => {
          const employeesInSplit = option.reduce((acc, item) => {
            if (item.split) return acc + 1;
            else return acc;
          }, 0);
          const total = option.reduce((acc, item) => {
            if (item.split) {
              acc.delivery_packages += item.delivery_packages;
              acc.delivery_stops += item.delivery_stops;
              acc.ecommerce_packages += item.ecommerce_packages;
              acc.ecommerce_stops += item.ecommerce_stops;
              acc.pickup_packages += item.pickup_packages;
              acc.pickup_stops += item.pickup_stops;
            }
            return acc;
          }, {
            delivery_packages: 0,
            delivery_stops: 0,
            ecommerce_packages: 0,
            ecommerce_stops: 0,
            pickup_packages: 0,
            pickup_stops: 0
          });
          return (
            <div key={index} className="flex flex-wrap items-center shadow-lg rounded bg-white my-12 mx-6 text-sm">
              {option.map((item, index) => {
                const employee = employees.find(employee => employee.id === item.employee_id);
                const route = routes.find(route => route.id === item.route_id);
                const company = companies.find(company => company.id === item.company_id);
                return (
                  <div className="w-full p-6" key={index}>
                    <div className="grid grid-cols-4 gap-4 p-4" key={index}>
                      <strong>date:</strong>
                      <div>{new Date(item.date).toDateString()}</div>
                      <strong>vehicle number:</strong>
                      <div>{item.vehicle_number}</div>
                      <strong>route:</strong>
                      <div>{route?.name}</div>
                      <strong>company:</strong>
                      <div>{company?.name}</div>
                      <strong>employee:</strong>
                      <div>{employee?.first_name} {employee?.last_name}</div>
                    </div>
                    <div className="flex flex-wrap space-x-2 ml-6 p-2">
                      <div>
                        <strong>delivery packages: </strong>
                        <span className={`${item.split ? 'text-green-700' : 'text-gray-600'}`}>
                             {item.split ? Math.round(total.delivery_packages / employeesInSplit) : item.delivery_packages}
                        </span>
                      </div>
                      <div>
                        <strong>delivery stops: </strong>
                        <span className={`${item.split ? 'text-green-700' : 'text-gray-600'}`}>
                            {item.split ? Math.round(total.delivery_stops / employeesInSplit) : item.delivery_stops}
                        </span>
                      </div>
                      <div>
                        <strong>ecommerce packages: </strong>
                        <span className={`${item.split ? 'text-green-700' : 'text-gray-600'}`}>
                            {item.split ? Math.round(total.ecommerce_packages / employeesInSplit) : item.ecommerce_packages}
                        </span>
                      </div>
                      <div>
                        <strong>ecommerce stops: </strong>
                        <span className={`${item.split ? 'text-green-700' : 'text-gray-600'}`}>
                            {item.split ? Math.round(total.ecommerce_stops / employeesInSplit) : item.ecommerce_stops}
                        </span>
                      </div>
                      <div>
                        <strong>pickup packages: </strong>
                        <span className={`${item.split ? 'text-green-700' : 'text-gray-600'}`}>
                            {item.split ? Math.round(total.pickup_packages / employeesInSplit) : item.pickup_packages}
                        </span>
                      </div>
                      <div>
                        <strong>pickup stops: </strong>
                        <span className={`${item.split ? 'text-green-700' : 'text-gray-600'}`}>
                            {item.split ? Math.round(total.pickup_stops / employeesInSplit) : item.pickup_stops}
                        </span>
                      </div>
                    </div>
                    <Button
                      className={`${item.split ? 'bg-green-600 hover:bg-green-700' : 'bg-blue-500 hover:bg-blue-700'} w-[50%] mx-auto my-4`}
                      onClick={() => handleSelect(option, item.employee_id)}>{item.split ? 'remove from split' : 'add to split'}</Button>
                  </div>
                )
              })}
            </div>
          )
        })}
        <div className="px-6 w-full flex items-center">
          <Button className={`max-w-[30rem] mx-auto my-4`} onClick={() => onSubmit(updatedOptions)}>Submit</Button>
        </div>
      </div>
    </div>
  );
};

export default SplitOptions;