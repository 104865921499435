import {RESET_STATE, SET_USER} from '../actions/types';

const user = (state= null, action) => {
    switch (action.type) {
        case RESET_STATE:
            return null;
        case SET_USER:
            return action.payload;
        default:
            return state;
    }
};

export default user;