import React from 'react';
import Input from "../../../../components/elements/Input";
import QuerySelectInput from "./QuerySelectInput";
import DateInput from "../../../../components/DateInput";

const QueryCreate = ({fields, handleChange, selected}) => {
  const inputComponents = {
    'text': Input,
    'select': QuerySelectInput,
    'date': DateInput
  };

  return (
    <div>
      {fields?.map((field, index, options) => {
          const InputComponent = inputComponents[field.type];

          return (
            <div key={index} className="mb-4">
              <InputComponent
                {...field}
                options={options}
                selected={selected}
                value={field.value || fields[field.name]}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          );
        }
      )}
    </div>
  );
};

export default QueryCreate;