import React from 'react';

const SubmitCredentialsButton = ({onClick}) => {
  return (
    <div className="flex space-x-3">
      <button
        className="flex-grow px-4 py-2 text-gray-500 border border-gray-500 rounded hover:bg-gray-500 hover:text-white"
        onClick={onClick}
        type="button" id="button-addon2">Submit FedEx Credentials
      </button>
    </div>
  );
};

export default SubmitCredentialsButton;