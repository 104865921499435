import React from 'react';


const SupportForm = () => {
 return (
   <form className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48">
     <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
       <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
         <div>
           <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
             First name
           </label>
           <div className="mt-2.5">
             <input
               type="text"
               name="first-name"
               id="first-name"
               autoComplete="given-name"
               className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
             />
           </div>
         </div>
         <div>
           <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
             Last name
           </label>
           <div className="mt-2.5">
             <input
               type="text"
               name="last-name"
               id="last-name"
               autoComplete="family-name"
               className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
             />
           </div>
         </div>
         <div className="sm:col-span-2">
           <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
             Email
           </label>
           <div className="mt-2.5">
             <input
               type="email"
               name="email"
               id="email"
               autoComplete="email"
               className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
             />
           </div>
         </div>
         <div className="sm:col-span-2">
           <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
             Phone number
           </label>
           <div className="mt-2.5">
             <input
               type="tel"
               name="phone-number"
               id="phone-number"
               autoComplete="tel"
               className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
             />
           </div>
         </div>
         <div className="sm:col-span-2">
           <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
             Message
           </label>
           <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    defaultValue={''}
                  />
           </div>
         </div>
       </div>
       <div className="mt-8 flex justify-end">
         <button
           type="button"
           className="rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-600"
         >
           Send message
         </button>
       </div>
     </div>
   </form>
 );
};

export default SupportForm;