import React from "react";
import GoogleButton from "../../components/elements/GoogleButton";


const SocialIcons = () => {
  return (
    <div className="px-6 sm:px-0 max-w-sm">
      <div className="w-full text-center pt-2 pb-4 text-gray-500"></div>
      <div className="w-full flex justify-center items-center">
       <GoogleButton/>
      </div>
    </div>
  )
}

export default SocialIcons;