import React from 'react';
import {Link} from "react-router-dom";
import {HiOutlineMail, HiOutlinePhone} from "react-icons/hi";


const ManagerCard = ({ name, email, phone, title }) => (
  <div className="rounded-2xl bg-gray-50 p-10">
    <h3 className="text-base font-semibold leading-7 text-gray-900">{name}</h3>
    <h2 className="text-base font-semibold leading-7 text-gray-600">{title}</h2>
    <dl className="mt-3 space-y-1 text-sm leading-6 text-gray-600">
      <div>
        <dt className="sr-only">Email</dt>
        <dd>
          <Link className="text-gray-400 hover:text-blue-500" to={`mailto:${email}`}>
            <HiOutlineMail className="inline-block"/> {email}
          </Link>
        </dd>
      </div>
      <div className="mt-1">
        <dt className="sr-only">Phone number</dt>
        <Link to={`tel:${phone}`} className="text-gray-400 hover:text-blue-500">
          <HiOutlinePhone className="inline-block"/> {phone}
        </Link>
      </div>
    </dl>
  </div>
);


export default ManagerCard;