import React from "react";
import RoadmapIcon from "../../components/icons/RoadmapIcon";

const RoadmapBadge = ({implemented}) => {
  return (
    <span className={`absolute flex items-center justify-center w-6 h-6 rounded-full -left-3 ring-8 ring-gray-50 ${implemented ? 'bg-blue-500' : 'bg-gray-400'}`}>
      <RoadmapIcon/>
    </span>
  )
}

export default RoadmapBadge;