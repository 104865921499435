import React from 'react';
import PayrollCard from "./payrols/PayrollCard";
import useAuthRedirect from "../../hooks/useAuthRedirect";


const Payrolls = () => {
  const today  = new Date().toISOString().slice(0,10);
  const myPayrolls = [
    {
      id: 1,
      total: 1230,
      date: today.toString(),
      company: "Seattle Ground",
      worked_hours: 43,
    },
    {
      id: 2,
      total: 1450,
      date: '2021-08-26',
      company: "Seattle Ground",
      worked_hours: 42,
    },
    {
      id: 3,
      total: 1540,
      date: '2021-08-19',
      company: "Seattle Ground",
      worked_hours: 45,
    }
  ]
  useAuthRedirect();
  return (
    <div className="grid grid-cols-3 gap-4 p-6">
      {myPayrolls.map((payroll, index) => <PayrollCard key={index} payroll={payroll}/>)}
    </div>
  );
};

export default Payrolls;