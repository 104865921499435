import React from 'react';
import { BsPencilSquare, BsCloudArrowUp } from "react-icons/bs";

const UserInfoEditButton = ({setEdit, edit, onSave, value}) => {
  const handleClick = () => {
    if (edit) onSave(value);
    setEdit(!edit);
  }
  return (
    <button type="button" onClick={handleClick}
            className="font-semibold text-gray-600 hover:text-blue-500 z-10">
      {!edit && <BsPencilSquare/>}
      {edit && <BsCloudArrowUp/>}
    </button>
  );
};

export default UserInfoEditButton;