import api from "../../app/request";
import store from "../../app/store";

const urls = {...store.getState().config.urls};

export const fetchMe = async () => {
  const res = await api.get(`${urls.me}`);
  if (res?.status === 200 && res?.data) {
    return res.data;
  } else {
    throw new Error("Error fetching user");
  }
};

export const fetchRefreshToken = async () => {
  const res = await api.get(`${urls.refresh}`);
  if (res?.status === 200 && res?.data) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching user");
};

export const fetchCompanies = async () => {
  const res = await api.get(`${urls.companies}/`);
  if (res?.status === 200 && res?.data && res?.data?.length > 0) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching companies");
};

export const createCompany = async ({name, service_area}) => {
  return api.post(`${urls.companies}/`, {name, service_area});
};


export const fetchVehicles = async () => {
  const res = await api.get(`${urls.vehicles}/`);
  if (res?.status === 200 && res?.data && res?.data?.length > 0) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching vehicles");
}

export const fetchEmployees = async () => {
  const res = await api.get(`${urls.employees}/`);
  if (res?.status === 200 && res?.data && res?.data?.length > 0) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching employees");

}

export const fetchCompanyRatesTemplate = async () => {
  const res = await api.get(`${urls.companyRates}${urls.template}/`);
  if (res?.status === 200 && res?.data) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching company rates template");
}

export const fetchCompanyRates = async () => {
  const res = await api.get(`${urls.companyRates}/`);
  if (res?.status === 200 && res?.data && res?.data?.length > 0) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching company rates");

}

export const fetchShiftTypes = async () => {
  const res = await api.get(`${urls.shiftTypes}/`);
  if (res?.status === 200 && res?.data && res?.data?.length > 0) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching shift types");
}


export const fetchUnits = async () => {
  const res = await api.get(`${urls.units}/`);
  if (res?.status === 200 && res?.data && res?.data?.length > 0) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching units");
}

export const fetchAccount = async () => {
  const res = await api.get(`${urls.accounts}/email`);
  if (res?.status === 200 && res?.data) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching account");
}

export const fetchPayStructures = async () => {
  const res = await api.get(`${urls.payStructures}/`);
  if (res?.status === 200 && res?.data && res?.data?.length > 0) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching pay structures");
}

export const fetchPayTypes = async () => {
  const res = await api.get(`${urls.payTypes}/`);
  if (res?.status === 200 && res?.data && res?.data?.length > 0) return res.data;
  else throw new Error("Error fetching pay types");
}

export const fetchRevenue = async () => {
  const res = await api.get(`${urls.revenue}/`);
  if (res?.status === 200 && res?.data && res?.data?.length > 0) return res.data;
  if (res?.status === 200 && res?.data && res?.data?.length === 0) return null;
  else throw new Error("Error fetching pay structures");
}

export const fetchTemplate = async () => {
  return  api.get(`${urls.companyRates}${urls.template}`);
}

export const removeCompany = async (id) => api.delete(`${urls.companies}/${id}`);
