import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import { useSelector} from "react-redux";


/**
 * ProfileButtons component renders logout and profile buttons
 * @param {string} hidden - hidden css class
 * @param hidden
 * @returns {JSX.Element}
 * @constructor
 */
const ProfileButtons = ({hidden = ''}) => {
  const {routes, d} = useSelector((state) => state.config);

  const cls = `${hidden} justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors`;


  return (
    <Fragment>
      <Link to={routes.logout} className={cls}>
        {d.logout.toUpperCase()}
      </Link>
      <Link to={routes.login} className={cls}>
        {d.profile.toUpperCase()}
      </Link>
    </Fragment>
  );
}

export default ProfileButtons;