import React, {useEffect, useState} from 'react'
import {
  HiOutlineUser,
  HiOutlineSquares2X2,
  HiOutlineDocumentChartBar,
  HiOutlineFingerPrint,
  HiOutlineQuestionMarkCircle,
  HiOutlineBuildingOffice2,
  HiOutlineChatBubbleBottomCenterText,
  HiOutlineArrowLeftOnRectangle,
  HiOutlineBriefcase
} from "react-icons/hi2";
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes} from "react-router-dom";
import MobileSidebar from "./elements/MobileSidebar";
import ProfileSidebar from "./elements/ProfileSidebar";
import ProfileHeader from "./elements/ProfileHeader";
import UserInfo from "./pages/UserInfo";
import Dashboard from "./pages/Dashboard";
import Payrolls from "./pages/Payrolls";
import Security from "./pages/Security";
import ProfileContactPage from "./pages/ProfileContactPage";
import {setAccount} from "../../actions";
import {useQuery} from "@tanstack/react-query";
import {fetchAccount} from "../hooks/queries";

const Profile = () => {
  const config = useSelector(state => state.config);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const {routes} = config;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSidebarFolded, setSidebarFolded] = useState(false);
  const account = useSelector(state => state.account);
  const accountData = useQuery({queryKey: ['account'], queryFn: fetchAccount, enabled: Boolean(!account && user)})

  useEffect(() => {
    if (accountData?.data?.id) dispatch(setAccount(accountData.data));
  }, [accountData, config.urls, dispatch]);


  const navigation = [
    account ? {name: 'Management', href: routes.account, icon: HiOutlineBriefcase, current: false} : undefined,
    {name: 'Dashboard', href: routes.profile, icon: HiOutlineSquares2X2, current: true},
    {name: 'Profile', href: `${routes.profile}${routes.userInfo}`, icon: HiOutlineUser, current: false},
    {name: 'Payrolls', href: `${routes.profile}${routes.payrolls}`, icon: HiOutlineDocumentChartBar, current: false},
    {name: 'Security', href: `${routes.profile}${routes.security}`, icon: HiOutlineFingerPrint, current: false},
    {name: 'Support', href: `${routes.profile}${routes.support}`, icon: HiOutlineQuestionMarkCircle, current: false},
  ]
  const links = [
    {
      id: 1,
      name: 'Companies',
      href: `${routes.profile}${routes.companies}`,
      icon: HiOutlineBuildingOffice2,
      current: false,
      display: true
    },
    {
      id: 2,
      name: 'Messages',
      href: `${routes.profile}${routes.messages}`,
      icon: HiOutlineChatBubbleBottomCenterText,
      current: false,
      display: true
    },
    {id: 2, name: 'Sign out', href: routes.logout, icon: HiOutlineArrowLeftOnRectangle, current: false, display: true},

  ];

  return (
    <div>
      <ProfileHeader links={links} navigation={navigation} isFolded={isSidebarFolded} setFolded={setSidebarFolded}
                     sidebarOpen={sidebarOpen} isSidebarFolded={isSidebarFolded} setSidebarOpen={setSidebarOpen}/>
      <ProfileSidebar navigation={navigation} links={links} isFolded={isSidebarFolded} setFolded={setSidebarFolded}/>
      <div className={`${isSidebarFolded ? 'lg:ml-36' : 'lg:pl-72'}`}>
        <MobileSidebar setSidebarOpen={setSidebarOpen}/>
        <div className="py-7">
          <Routes>
            <Route path={`${routes.support}`} element={<ProfileContactPage/>}/>
            <Route path={`${routes.security}`} element={<Security/>}/>
            <Route path={`${routes.payrolls}`} element={<Payrolls/>}/>
            <Route path={`${routes.userInfo}`} element={<UserInfo/>}/>
            <Route path="*" element={<Dashboard/>}/>
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default Profile;