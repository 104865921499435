import {RESET_STATE, SET_TIME_DATA} from '../actions/types';

const timeData = (state = [], action) => {
  switch (action.type) {
    case RESET_STATE:
      return [];
    case SET_TIME_DATA:
      return action.payload;
    default:
      return state;
  }
};

export default timeData;