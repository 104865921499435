import {RESET_STATE, SET_CONFIG} from '../actions/types';

const initialState = {
  reFetchTime: 1740000, // in milliseconds 29 minutes
  urls: {
    accounts: '/accounts',
    base: '/api',
    bulk: '/bulk',
    devBase: 'http://127.0.0.1/api',
    users: '/users',
    upload: '/upload',
    login: '/auth/login',
    logout: '/auth/logout',
    uploadUsers: '/users/upload',
    signup: '/users/new',
    me: '/users/me',
    refresh: '/auth/refresh',
    fedexId: '/users/fedex-id',
    expired: '/auth/refresh',
    googleAuth: '/auth/google',
    secrets: '/secrets',
    template: '/template',
    companyRates: '/company-rates',
    timeSheets: '/time-sheets',
    uploadTimeSheet: '/time-sheets/upload',
    saveTimeSheet: '/time-sheets/save',
    outputData: '/driver-data',
    uploadOutputSheet: '/driver-data/upload',
    employees: '/employees',
    uploadRevenue: '/company-revenue/upload',
    payrolls: '/payrolls',
    payrollsGenerate: '/payrolls/generate',
    companies: '/companies',
    vehicles: '/vehicles',
    vehiclesUpload: '/vehicles/upload',
    units: '/units',
    vir: '/vehicles-inspections',
    payStructures: '/pay-structures',
    shiftTypes: '/shift-types',
    payTypes: '/pay-types',
  },
  routes: {
    home: '/',
    about: '/about',
    account: '/account',
    login: '/login',
    logout: '/logout',
    signup: '/signup',
    profile: '/profile',
    userInfo: '/user-info',
    security: '/security',
    support: '/support',
    messages: '/messages',
    management: '/management',
    payrolls: '/payrolls',
    companies: '/companies',
    employees: '/employees',
    vehicles: '/vehicles',
    serverStatus: '/status',
    revenues: '/revenues',
    settings: '/settings',
    tasks: '/tasks',
    roadmap: '/roadmap',
    cookiePolicy: '/cookie-policy',
    privacyPolicy: '/privacy-policy',
    termsOfService: '/terms-of-service',
    jobs: '/jobs',
    shiftTypes: '/shift-types',
    registerBusinessAccount: "/register-business-account",
    learnMore: "/learn-more",
    dataManagement: '/data-management',
    companyRates: '/company-rates',
  },
  re: {
    esr: /^[a-zA-Z(?:\-)]{3,}$/
  },
  d: {
    home: 'home',
    copyright: 'AllGround © 2022',
    login: 'login',
    logout: 'logout',
    signup: 'signup',
    submit: 'submit',
    profile: 'profile',
    email: 'email',
    password: 'password',
    createAccount: 'create account',
    first_name: 'first_name',
    last_name: 'last_name',
    fdx_id: 'fdx_id',
    dob: 'dob',
    hiring_date: 'hiring_date',
    fedExID: 'FedEx ID',
    confirm_password: 'confirm_password',
    timeData: 'Time data',
    output: 'Output data',
    revenue: 'Company revenue',
    generatePayrolls: 'Generate payrolls',
    Payrolls: 'Payrolls',
    Payroll: 'Payroll',
    settings: 'Settings',
    vehicleInspections: 'Vehicle Inspections',
    uploadVehicles: 'Upload Vehicles'
  },
  msg: {
    emailError: 'This doesn\'t look like an email address',
    passwordError: 'Must contain one uppercase, one digit, and one special character.',
    nameError: 'Should contain only english letters or - or \' ',
    fdxIdError: 'Must contain 7 digits',
    confirmPasswordError: 'Passwords doesn\'t match',
    unknownServerError: 'Something went wrong. Try it later',
    networkError: 'Cannot connect to the server, notify IT department',
    notFound: 'Resource not found',
    notRecords: 'There are no records to display',
    invalidWeekEnd: 'Weekend date should end on a Friday',
    esrNameError: 'Employee Shift Role name can contains only letters and \'-\'',
    removeRecord: 'Remove record',
    goToPayrollsPage: 'Payrolls was generated successfully! Please open payrolls page to see them.'
  },
  specs: {
    revenue: [`brand promotion revenue`, `brand promotion vehicles`, `date`,
      `delivery packages amount`, `delivery packages revenue`, `delivery stops amount`, `delivery stops revenue`,
      `EC packages amount`, `EC packages revenue`, `EC stops amount`, `EC stops revenue`, `fuel`,
      `large packages mix amount`, `large packages mix revenue`, `net revenue`, `packages delivery revenue`,
      `packages pickup amount`, `packages pickup revenue`, `pickup stops amount`, `pickup stops revenue`, `surge`, `company`]
  },
  icons: {
    fedex: `M 16 11 L 16 14.949219 L 15.919922 14.949219 C 15.439922 14.374219 14.845391 14.175781 14.150391 
        14.175781 C 12.730391 14.175781 11.660156 15.190438 11.285156 16.523438 C 10.385156 13.416438 6.4144531 
        13.510016 5.1894531 15.791016 L 5.1894531 14.525391 L 2.4492188 14.525391 L 2.4492188 13.154297 L 5.4492188 
        13.154297 L 5.4492188 11.005859 L 0 11.005859 L 0 20.648438 L 2.4492188 20.648438 L 2.4492188 16.595703 L 
        4.8945312 16.595703 C 4.8195313 16.893703 4.7792969 17.213828 4.7792969 17.548828 C 4.7792969 21.371828 
        9.9090625 22.327109 11.289062 18.787109 L 9.1894531 18.787109 C 8.4544531 19.880109 6.9003906 19.252438 
        6.9003906 18.023438 L 11.175781 18.023438 C 11.360781 19.618438 12.544688 21 14.179688 21 C 14.884688 21 
        15.530781 20.639344 15.925781 20.027344 L 15.949219 20.027344 L 15.949219 20.648438 L 26.560547 20.648438 L 
        27.664062 19.341797 L 28.779297 20.648438 L 32 20.648438 L 29.300781 17.484375 L 31.964844 14.351562 L 
        28.845703 14.351562 L 27.759766 15.650391 L 26.634766 14.353516 L 20.699219 14.353516 L 20.699219 13.515625 L 
        23.505859 13.515625 L 23.505859 11 L 16 11 z M 18.304688 11.466797 L 23.054688 11.466797 L 23.054688 13.044922 
        L 20.25 13.044922 L 20.25 14.818359 L 23.025391 14.818359 L 23.025391 16.287109 L 20.220703 16.287109 L 
        20.220703 18.626953 L 23.025391 18.626953 L 23.025391 20.173828 L 18.304688 20.173828 L 18.304688 11.466797 z 
        M 23.535156 14.515625 L 26.070312 17.496094 L 23.535156 20.476562 L 23.535156 18.091797 L 20.730469 18.091797 L 
        20.730469 16.816406 L 23.535156 16.816406 L 23.535156 14.515625 z M 24.449219 14.818359 L 26.435547 14.818359 L 
        27.765625 16.349609 L 29.044922 14.818359 L 30.970703 14.818359 L 28.699219 17.484375 L 31 20.173828 L 28.974609 
        20.173828 L 27.660156 18.630859 L 26.355469 20.173828 L 24.449219 20.173828 L 26.730469 17.496094 L 24.449219 
        14.818359 z M 8.1523438 15.599609 C 8.6654688 15.604234 9.1690625 15.947688 9.2890625 16.617188 L 6.9648438 
        16.617188 C 7.1173437 15.929188 7.6392187 15.594984 8.1523438 15.599609 z M 14.634766 15.890625 C 16.264766 
        15.890625 16.359766 19.175781 14.634766 19.175781 C 12.904766 19.175781 12.934766 15.890625 14.634766 15.890625 
        z`,
  }
};

const config = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return initialState;
    case SET_CONFIG:
      return action.payload;
    default:
      return state;
  }
};


export default config;