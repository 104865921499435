import React from "react";


const IconWrapper = ({children, className, viewBox="0 0 20 20", fill="currentColor", ...props}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} fill={fill} viewBox={viewBox} {...props}>
      {children}
    </svg>
  );
}

export default IconWrapper;