import {RESET_STATE, SET_SERVER_STATUS} from '../actions/types';

const serverStatus = (state = true, action) => {
  switch (action.type) {
    case RESET_STATE:
      return true;
    case SET_SERVER_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export default serverStatus;