import {RESET_STATE, SET_USERS} from '../actions/types';

const users = (state= null, action) => {
  switch (action.type) {
    case RESET_STATE:
      return null;
    case SET_USERS:
      return action.payload;
    default:
      return state;
  }
};

export default users;